import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { safeGetProperty } from '../../helpers/customMethods';

export default function isAuthenticated(ComposedComponent, roles, preferredRedirectPath) {
  class AuthRoute extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    UNSAFE_componentWillMount() {
      this.handleRedirection(this.props, () => this.handleUnAuthenticatedRedirect(this.props));
    }

    UNSAFE_componentWillUpdate(nextProps) {
      this.handleRedirection(nextProps, () => this.handleUnAuthenticatedRedirect(nextProps));
    }

    handleRedirection = (props, fn) => {
      const { user } = props;
      const hasRoleAccess = !roles || roles.includes(user.role);

      if (!hasRoleAccess && user.authenticated && user.role) {
        props.history.push(preferredRedirectPath);
        return null;
      }

      if (fn && typeof fn === 'function') {
        fn();
      }
      return null;
    }

    handleUnAuthenticatedRedirect = (props) => {
      const { user, history } = props;

      if (!user.authenticated) {
        const prevPath = safeGetProperty('pathname', history.location);
        const queryParams = safeGetProperty('search', history.location);
        if (!prevPath === '/prospect-landing-page') {
          const { prospect_id, listing_id } = this.props.match.params;
          history.push({
            pathname: '/prospect-landing-page',
            search: queryParams,
            state: {
              prospect_id,
              listing_id,
              redirect_path: this.props.location.pathname,
              query: queryParams,
            },
          });
        } else {
          history.push(`/?redirect=${prevPath}`);
        }
      }
      return null;
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <ComposedComponent {...this.props} {...this.state} />;
    }
  }

  const mapStateToProps = ({ user }) => ({ user });
  return withRouter(connect(mapStateToProps, null)(AuthRoute));
}
