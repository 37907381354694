import { bugsnagReactClient as bugsnagClient } from './bugsnag';

export default class XHR {
  constructor(config = {}) {
    this.xhr = new XMLHttpRequest();
    this.defaults = {
      headers: {
        common: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      },
    };

    this.baseURL = config.baseURL;
  }

  setHeaders() {
    Object.entries(this.defaults.headers.common)
      .forEach(([key, value]) => {
        this.xhr.setRequestHeader(key, value);
      });
  }

  objectifyHeaders() {
    const headers = this.xhr.getAllResponseHeaders().trim().split('\r\n');

    return headers.reduce((acc, current) => {
      const [key, value] = current.split(': ');
      acc[key.toLowerCase()] = value;
      return acc;
    }, {});
  }

  serializeSuccessReponse() {
    const headers = this.objectifyHeaders();

    try {
      return {
        headers,
        data: JSON.parse(this.xhr.responseText),
      };
    } catch (error) {
      if (!/<\/?[a-z][\s\S]*>/i.test(this.xhr.responseText)) {
        bugsnagClient.notify(error);
      }

      return {
        headers,
        data: {},
      };
    }
  }

  serializeErrorReponse() {
    const headers = this.objectifyHeaders();

    try {
      return {
        headers,
        response: this.xhr.responseText ? JSON.parse(this.xhr.responseText) : {},
      };
    } catch (error) {
      if (!/<\/?[a-z][\s\S]*>/i.test(this.xhr.responseText)) {
        bugsnagClient.notify(error);
      }

      return {
        headers,
        response: {},
      };
    }
  }

  request() {
    return new Promise((resolve, reject) => {
      this.xhr.onreadystatechange = () => {
        const okResponse = this.xhr.readyState === 4 && this.xhr.status === 200;
        const errorResponse = this.xhr.status < 200 || this.xhr.status >= 300;

        if (okResponse) {
          return resolve(this.serializeSuccessReponse());
        }

        if (errorResponse) {
          return reject(this.serializeErrorReponse());
        }

        return null;
      };

      this.xhr.addEventListener('error', () => {
        // throw Error('an unexpected error occurred');
      });
    });
  }

  get(url) {
    const apiURL = `${this.baseURL}${url}`;

    this.xhr.open('GET', apiURL, true);
    this.setHeaders();
    this.xhr.send();
    return this.request();
  }

  post(url, payload) {
    const apiURL = `${this.baseURL}${url}`;

    this.xhr.open('POST', apiURL, true);
    this.setHeaders();
    this.xhr.send(payload);
    return this.request();
  }

  put(url, payload) {
    const apiURL = `${this.baseURL}${url}`;

    this.xhr.open('PUT', apiURL, true);
    this.setHeaders();
    this.xhr.send(payload);
    return this.request();
  }

  delete(url, payload) {
    const apiURL = `${this.baseURL}${url}`;

    this.xhr.open('PUT', apiURL, true);
    this.setHeaders();
    this.xhr.send(payload);
    return this.request();
  }
}
