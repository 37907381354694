export const roles = {
  superAdmin : "Super Admin",
  accountAdmin: "Account Admin",
  companyAdmin: "Company Admin",
  integrationManager: "Integration Manager",
  manager: "Manager",
  consultant: "Consultant",
  regular: "Regular"
}

export const roleRanks = {
  "manager" : 1,
  "consultant" : 2,
  "company admin" : 3,
  "super admin" : 4,
  "account admin" : 5,
  "integration manager" : 6,
  "regular" : 7
}
