import actionTypes from '../constants/actionTypes';

export const pageLoading = (key, data) => ({
  type: actionTypes.PAGE_LOADING,
  data,
  key,
});

export const objectWithPagination = (response, filters = {}) => ({
  data: response.data,
  totalPages: response.headers.total,
  currentPage: filters.page_number || 1,
});
