import actionTypes from '../constants/actionTypes';

const initialPaymentsState = {
  paymentMethods: {
    data: [],
    error: null,
    loading: false,
  },
  processPayment: {
    data: null,
    error: null,
    loading: false,
  },
};

export const paymentMethods = (
  state = initialPaymentsState.paymentMethods,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHODS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case actionTypes.FETCH_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionTypes.UPDATE_PAYMENT_METHODS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.payment_method.id) {
            return {
              ...item,
              ...action.payload.payment_method,
            };
          }
          if (item.default) {
            return {
              ...item,
              default: false,
            };
          }
          return item;
        }),
        loading: false,
      };
    case actionTypes.UPDATE_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionTypes.DELETE_PAYMENT_METHODS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (item) => item.id !== action.payload.payment_method.id,
        ),
        loading: false,
      };
    case actionTypes.DELETE_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionTypes.ADD_ONE_TIME_PAYMENT_METHOD:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    default:
      return state;
  }
};

export const processPayment = (
  state = initialPaymentsState.processPayment,
  action,
) => {
  switch (action.type) {
    case actionTypes.PROCESS_PAYMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case actionTypes.PROCESS_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
