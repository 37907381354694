import compose from 'lodash/fp/compose';
import orderBy from 'lodash/fp/orderBy';
import identity from 'lodash/fp/identity';
import concat from 'lodash/fp/concat';
import filter from 'lodash/fp/filter';
import reject from 'lodash/fp/reject';

import actionTypes from '../constants/actionTypes';

const statusCountNames = (status) => {
  switch(status) {
    case 'lead':
      return 'open_leads_count';
    case 'active':
      return 'active_leads_count';
    case 'moved_in':
      return 'moved_in_count';
    case 'denied_or_lost':
      return 'denied_lost_count';
  }
}

const initialPropertyState = {
  prospect      : [],
  lead          : [],
  active       : [],
  moved_in      : [],
  denied_or_lost: [],
  onload_leads_count: {},
  hideSeeMoreOpen: false,
  hideSeeMoreActive: false,
  hideSeeMoreMovedIn: false,
  hideSeeMoreDenied: false,
  loading       : true,
  totalPages    : 0,
  currentPage   : 1,
};

const recompose = (state, action) => {
  let composer                   = null;
  const newState                 = {};
  let onload_leads_count         = null;
  const prospect                 = action.data;
  const addProspectToStatus      = concat(prospect);
  const sortProspects            = orderBy(['created_at'], ['asc']);
  const currentStatusState       = identity(state[prospect.status]);
  const previousStatusState      = identity(state[prospect.previous_status]);
  const filterProspect           = filter(currentProspect => currentProspect.id !== prospect.id);
  const removeProspectFromStatus = reject(currentProspect => currentProspect.id === prospect.id);

  composer = compose(sortProspects, addProspectToStatus, filterProspect);
  newState[prospect.status] = composer(currentStatusState);

  if (prospect.expected_status !== prospect.previous_status) {
    composer                           = compose(removeProspectFromStatus, filterProspect);
    newState[prospect.previous_status] = composer(previousStatusState);
    onload_leads_count = state.onload_leads_count;

    if (prospect.expected_status) {
      onload_leads_count[statusCountNames(prospect.previous_status)] -= 1
      onload_leads_count[statusCountNames(prospect.expected_status)] += 1
    }
  } else if (prospect.expected_status === prospect.previous_status){
    composer = compose(sortProspects, addProspectToStatus);
    newState[prospect.status] = composer(currentStatusState);
  } else {
    const expectStatusState            = identity(state[prospect.expected_status]);
    composer                           = compose(removeProspectFromStatus, filterProspect);
    newState[prospect.previous_status] = composer(expectStatusState);    
  }

  return Object.assign({}, state, newState, onload_leads_count);
}

export const leads = (state = initialPropertyState, action) => {
  switch (action.type) {
    case  actionTypes.LOADING_LEADS:
      return Object.assign({}, { ...action.data });
    case actionTypes.FETCH_LEADS_SUCCESS:
      return Object.assign({}, { ...action.data, loading: false });
    case actionTypes.FETCH_LEADS_FAILURE:
      return Object.assign({}, state,  {
        lead          : [],
        active        : [],
        moved_in      : [],
        denied_or_lost: [],
        loading       : false,
      });
    case actionTypes.FETCH_OPEN_LEADS_SUCCESS:
      return Object.assign({}, {
        lead: state.lead.concat(action.data.lead),
        active: state.active,
        moved_in: state.moved_in,
        denied_or_lost: state.denied_or_lost,
        hideSeeMoreOpen: action.data.hideSeeMoreOpen,
        onload_leads_count: state.onload_leads_count,
        loading: false
      });
    case actionTypes.FETCH_OPEN_LEADS_FAILURE:
      return Object.assign({}, state,  {
        lead          : state.lead,
        loading       : false,
      });
      case actionTypes.FETCH_ACTIVE_LEADS_SUCCESS:
        return Object.assign({}, {
          lead: state.lead,
          active: state.active.concat(action.data.active),
          moved_in: state.moved_in,
          denied_or_lost: state.denied_or_lost,
          hideSeeMoreActive: action.data.hideSeeMoreActive,
          onload_leads_count: state.onload_leads_count,
          loading: false
        });
      case actionTypes.FETCH_ACTIVE_LEADS_FAILURE :
        return Object.assign({}, state,  {
          active       : state.active,
          loading       : false,
        });
      case actionTypes.FETCH_MOVED_IN_LEADS_SUCCESS:
        return Object.assign({}, {
          lead: state.lead,
          active: state.active,
          moved_in: state.moved_in.concat(action.data.moved_in),
          denied_or_lost: state.denied_or_lost,
          hideSeeMoreMovedIn: action.data.hideSeeMoreMovedIn,
          onload_leads_count: state.onload_leads_count,
          loading: false
        });
      case actionTypes.FETCH_MOVED_IN_LEADS_FAILURE:
        return Object.assign({}, state,  {
          moved_in      : state.moved_in,
          loading       : false,
        });
        case actionTypes.FETCH_DENIED_LOST_SUCCESS:
          return Object.assign({}, {
            lead: state.lead,
            active: state.active,
            moved_in: state.moved_in,
            denied_or_lost: state.denied_or_lost.concat(action.data.denied_or_lost),
            hideSeeMoreDenied: action.data.hideSeeMoreDenied,
            onload_leads_count: state.onload_leads_count,
            loading: false
          });
        case actionTypes.FETCH_DENIED_LOST_FAILURE:
          return Object.assign({}, state,  {
            denied_or_lost: state.denied_or_lost,
            loading       : false,
          });
    case actionTypes.UPDATE_LEAD_SUCCESS:
      return recompose(state, action);
    case actionTypes.UPDATE_LEAD_ERROR:
      return state;
    default:
      return state;
  }
};
