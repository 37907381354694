function createResidencyQueryString(filters) {
  let queries = '';

  if (filters.search) {
    queries += `q[user_full_name_cont]=${filters.search}&q[unit_number_eq]=${filters.search}&q[m]=or&`;
  }

  return `${queries}page=${filters.page_number || 1}`;
}

function createPropspectsQueryString(filters) {
  let queries = '';

  if (filters.search) {
    queries += `q[user_user_name_cont]=${filters.search}&q[user_email_eq]=${filters.search}&q[user_phone_number_eq]=${filters.search}&q[m]=or&`;
  }

  return `${queries}page=${filters.page_number || 1}`;
}

function createListingProspectsQueryString(filters) {
  let queries = '';

  if (filters.search) {
    queries += `q[user_first_name_cont]=${filters.search}&q[user_user_name_cont]=${filters.search}&q[user_email_eq]=${filters.search}&q[m]=or&`;
  }

  if (filters.is_all) {
    if (queries !== '') {
      queries += '&';
    }
    queries += `is_all_prospects=${filters.is_all}&`;
  }

  return `${queries}page=${filters.page_number || 1}`;
}

function createSearchProspectsByResidentQueryString(filters) {
  let queries = '';

  if (filters.search) {
    queries += `${queries}q[first_resident_first_name_cont]=${filters.search}&q[first_resident_last_name_cont]=${filters.search}&`;
    queries += `q[second_resident_first_name_cont]=${filters.search}&q[second_resident_last_name_cont]=${filters.search}&q[m]=or&`;
  }

  return `${queries}page=${filters.page_number || 1}`;
}

function createPropertiesQueryString(filters) {
  let queries = '';

  if (filters.search) {
    queries += `q[name_cont]=${filters.search}&`;
  }

  if (filters.disable_pagination) {
    queries += '&disable_pagination=true';
    return queries;
  }

  return `${queries}page=${filters.page_number || 1}`;
}

function createNeighborNurtureQueryString(filters = { page_number: 1 }) {
  let queries = `page=${filters.page_number}`;

  if (filters.filter) {
    queries = `${queries}&filter=${filters.filter}`;
  }

  if (filters.search) {
    queries = `${queries}&search=${filters.search}`;
  }

  if (filters.disable_pagination) {
    queries += '&disable_pagination=true';
    return queries;
  }

  return queries;
}

function getField(filters) {
  if (filters.id) {
    return `${`/${filters.id}`}`;
  }
  return '';
}

function createProximityWordsQueryString(filters) {
  const isProspectQuery = `is_prospect=${filters.isProspect}`;
  const entityQuery = `&entity=${filters.entity || 'experience'}`;
  const queries = `${isProspectQuery}${entityQuery}`;
  return queries;
}

function createRewardsQueryString(filters = {}) {
  let queries;
  if (!filters.reward_ids) {
    const rewardQuery = `view=${filters.view || ''}`;
    const pageQuery = `&page=${filters.page_number || 1}`;
    const searchQuery = filters.search ? `&search=${filters.search}` : '';
    const sortQuery = filters.sort ? `&sort=${filters.sort}` : '';
    const isRewardPageQuery = filters.rewards_page
      ? `&rewards_page=${filters.rewards_page}`
      : '';
    queries = `${rewardQuery}${pageQuery}${searchQuery}${sortQuery}${isRewardPageQuery}`;
  } else {
    queries = `reward_ids=${filters.reward_ids}`;
  }

  return queries;
}

function createGenericQueryString(filters = { page_number: 1 }) {
  let pageQuery = `page=${filters.page_number || 1}`;

  if (filters.filter) {
    pageQuery = `${pageQuery}&filter=${filters.filter}`;
  }

  if (filters.search) {
    pageQuery = `${pageQuery}&search=${filters.search}`;
  }

  if (filters.status && filters.status.length) {
    pageQuery = `${pageQuery}&status=${filters.status}`;
  }

  if (filters.start_date) {
    pageQuery = `${pageQuery}&start_date=${filters.start_date}`;
  }

  if (filters.end_date) {
    pageQuery = `${pageQuery}&end_date=${filters.end_date}`;
  }

  return pageQuery;
}

function setParams() {
  return 'view=unrewarded';
}

function getTimeZone() {
  const region1 = new Intl.DateTimeFormat();
  return region1.resolvedOptions().timeZone;
}

export function sortListByKey(key) {
  return function sortFn(objA, objB) {
    if (objA[key] < objB[key]) {
      return -1;
    }
    if (objA[key] > objB[key]) {
      return 1;
    }
    return 0;
  };
}

// SERVER ROUTES
export const ROUTES = {
  // SIGN IN
  SIGN_IN: '/api/manager/sessions',
  REFRESH_TOKEN: (token) => `/api/manager/sessions/${token}`,

  // PASSWORD RESET
  RESET_PASSWORD: '/api/manager/password_resets',

  // USER
  CURRENT_USER: '/api/manager/manager_users',
  USER_INVITATIONS: '/api/manager/invitations',
  FETCH_USER_INVITATIONS: (listingId) => `/api/manager/invitations/${listingId}/invited_users`,
  CANCEL_USER_INVITE: (user_id) => `/api/manager/invitations/${user_id}`,
  CANCEL_INACTIVE_USER_INVITE: (listingId) => `/api/manager/invitations/${listingId}/cancel_user_invite`,
  CREATE_NEW_PASSWORD: '/api/manager/token_authentication',
  VERIFY_DEFAULT_PASSWORD: (default_password) => `/api/manager/token_authentication/${default_password}`,
  POST_AVATAR: '/api/manager/manager_user_images',

  UPDATE_PROFILE: (id) => `/api/manager/manager_users/${id}`,
  UPDATE_PASSWORD: '/api/manager/manager_authentications',

  // BILLING
  ADD_DWOLLA_ACCOUNT: (listingId) => `/api/manager/subscriptions/${listingId}/add_dwolla_account_to_listing`,
  ADD_STRIPE_CARD_ACCOUNT: '/api/manager/subscriptions/add_stripe_card_account',
  ADD_STRIPE_BANK_ACCOUNT: '/api/manager/subscriptions/add_stripe_bank_account',
  REMOVE_STRIPE_ACCOUNT: '/api/manager/subscriptions/delete_billing_account',
  FETCH_USER_BILLING_ACCOUNTS:
    '/api/manager/subscriptions/user_billing_accounts',
  VERIFY_BANK_ACCOUNT: '/api/manager/subscriptions/verify_bank_account',
  UPDATE_PROPERTY_BILLING_CONTACT: (listingId) => `/api/manager/activate_tab/${listingId}/update_billing_account`,
  ADD_BILLING_ADDRESS: '/api/manager/subscriptions/add_billing_address',

  // PROPERTIES
  PROPERTIES: (filters = {}) => `/api/manager/listings${getField(filters)}?${createPropertiesQueryString(
    filters,
  )}`,
  PROPERTY: (id) => `/api/manager/listings/${id}`,
  PROPERTY_AUTO_INVITER: (id) => `/api/manager/listings/${id}/auto_inviter`,
  PROPERTY_VALIDATE_INTEGRATION: (id) => `/api/manager/listings/${id}/listing_third_party_integrations`,
  PROPERTY_DELETE_INTEGRATION: (id, type) => `/api/manager/listings/${id}/listing_third_party_integrations/${type}`,
  PROPERTY_MANAGER_VALIDATE_INTEGRATION: (id) => `/api/manager/property_managers/${id}/manager_third_party_integrations`,
  PROPERTY_MANAGER_DELETE_INTEGRATION: (id, type) => `/api/manager/property_managers/${id}/manager_third_party_integrations/${type}`,
  PROPERTY_KNOCK_UPDATES: (id) => `/api/manager/listings/${id}/knock_update`,
  PROXIMITY_WORDS: (listing_id, filters) => `/api/manager/listings/${listing_id}/proximity_words?${createProximityWordsQueryString(
    filters,
  )}`,
  RESIDENT_RESPONSES: (listing_id, filters) => `/api/manager/listings/${listing_id}/resident_responses?${createProximityWordsQueryString(
    filters,
  )}`,
  TOP_RESIDENTS: (listing_id) => `/api/manager/listings/${listing_id}/top_residents`,
  MOVED_IN_INTERESTS: (listing_id) => `/api/manager/listings/${listing_id}/moved_in_interests`,
  LOST_CONCERNS: (listing_id) => `/api/manager/listings/${listing_id}/lost_concerns`,
  REWARD_SNAPSHOT: (listing_id) => `/api/manager/listings/${listing_id}/reward_snapshot`,
  PROPERTY_MANAGER: '/api/manager/property_managers/pm',
  PROPERTY_MANAGERS: (filters = {}, fetchAll = false) => (`/api/manager/property_managers${fetchAll ? '' : `?${createPropertiesQueryString(filters)}`}`),
  PROPERTY_MANAGER_USERS: (filters = {}) => `/api/manager/property_manager_users?page=${filters.page_number || 1}&q=${
    filters.search || ''
  }`,
  PROPERTY_MANAGER_USER: (id) => `/api/manager/property_manager_users/${id}`,
  PROPERTY_USERS: (listingId, filters = {}) => `/api/manager/listings/${listingId}/listing_users?page=${
    filters.page_number || 1
  }`,
  PROPERTY_RESIDENT_CONVERSATIONS: (listingId, residencyId, filters = {}) => `/api/manager/listings/${listingId}/resident_conversations/${residencyId}?page=${
    filters.page_number || 1
  }`,
  PROPERTY_NOTIFICATIONS: (listing_id) => `/api/manager/listings/${listing_id}/listing_notifications?${setParams()}`,
  ENTITY_SENTIMENT: (listing_id, is_prospect) => `/api/manager/listings/${listing_id}/entity_sentiment?is_prospect=${is_prospect}`,
  INVITE_RESIDENTS: (listing_id) => `/api/manager/listings/${listing_id}/residency_invitations`,
  INVITE_TEAM: () => '/api/manager/invitations/invite_users',
  RE_INVITE_USER: () => '/api/manager/invitations/reinvite_user',
  FETCH_PAYMENT_HISTORY: (listingId, year) => `/api/manager/subscriptions/${listingId}/payment_history?year=${year}`,
  EXPORT_PAYMENT_INVOICE: '/api/manager/subscriptions/send_invoice',
  RESUME_SERVICE: (listingId) => `/api/manager/listings/${listingId}/resume_service`,
  RETRY_PAYMENT: (listingId) => `/api/manager/listings/${listingId}/retry_payment`,
  POST_MESSENGER_CONFIG: (listingId) => `/api/manager/listings/${listingId}/post_messenger_config`,

  // ACTIVATE TAB
  SEND_WIDGET_INSTALLATION_INSTRUCTION: (listingId) => `/api/manager/activate_tab/${listingId}/send_installation_instructions`,
  UPDATE_ANNOUNCE_WIDGET: (listingId) => `/api/manager/activate_tab/${listingId}/set_announce_pdf`,
  UPDATE_PRINT_AND_MAKE_COPIES: (listingId) => `/api/manager/activate_tab/${listingId}/set_print_and_make_copies`,
  ACTIVATE_SUBSCRIPTION: (listingId) => `/api/manager/activate_tab/${listingId}/activate_subscription`,

  // CONVERSATIONS
  PROPERTY_CONVERSATIONS: (listingId, filters = {}) => `/api/manager/listings/${listingId}/conversations?page=${
    filters.page_number || 1
  }`,

  // FLOORPLANS
  PROPERTY_FLOORPLANS: (listingId, filters = {}) => `/api/manager/listings/${listingId}/listing_floorplans?page=${
    filters.page_number || 1
  }`,

  // RESIDENCY
  RESIDENCIES: (listing_id, filters = {}) => `/api/manager/listings/${listing_id}/residencies?${createResidencyQueryString(
    filters,
  )}`,
  RESIDENT: (listing_id, resident_id) => `/api/manager/listings/${listing_id}/residencies/${resident_id}`,
  RESIDENTS_ANALYTICS: (listing_id) => `/api/manager/listings/${listing_id}/residents_analytics`,

  // REWARDS
  REWARDS: (listing_id, filters) => `/api/manager/listings/${listing_id}/prospect_rewards?${createRewardsQueryString(
    filters,
  )}`,
  ALL_REWARDS: (filters) => `/api/manager/listings/prospect_rewards?${createRewardsQueryString(
    filters,
  )}`,
  // RG PAY
  ENABLE_REWARDS: '/api/manager/rewards/enable_rewards',
  DISABLE_REWARDS: '/api/manager/rewards/disable_rewards',
  REWARDS_STATS_RGPAY: () => '/api/manager/rewards/stats',
  REWARDS_RGPAY: (filters) => `/api/manager/rewards?${createRewardsQueryString(filters)}`,
  INDIVIDUAL_REWARDS_RGPAY: (filters) => `/api/manager/rewards/reward_details?${createRewardsQueryString(filters)}`,
  ALL_REWARDS_STATS: (filters) => `/api/manager/rewards?${createRewardsQueryString(filters)}`,
  RESIDENT_REWARDS_TABLE: (listing_id, user_id) => `/api/manager/listings/${listing_id}/prospect_rewards/${user_id}`,
  PROSPECT_REWARDS: (listing_id, user_id, filters) => `/api/manager/listings/${listing_id}/prospect_rewards/${user_id}?${createRewardsQueryString(
    filters,
  )}`,
  ENABLE_OR_DISABLE_REWARD: (listing_id, prospect_id, filters) => `/api/manager/listings/${listing_id}/prospect_rewards/${prospect_id}/enable_or_disable?${createRewardsQueryString(
    filters,
  )}`,
  MARK_REWARDED: '/api/manager/rewards/mark_rewarded',

  // REPORTS
  REPORT_RESIDENCY: '/api/manager/reports',

  // PROSPECTS
  PROSPECTS: (filters = {}) => `/api/manager/prospects?${createPropspectsQueryString(filters)}`,
  PROPERTY_PROSPECTS: (property_id, filters = {}) => `/api/manager/listings/${property_id}/listing_prospects?${createListingProspectsQueryString(
    filters,
  )}`,
  PROPERTY_PROSPECTS_BY_RESIDENT: (property_id, filters = {}) => `/api/manager/listings/${property_id}/listing_prospects?${createSearchProspectsByResidentQueryString(
    filters,
  )}`,
  PROPERTY_PROSPECT: (property_id, prospect_id, action_required) => `/api/manager/listings/${property_id}/listing_prospects/${prospect_id}?action=${action_required}`,
  CONVERSATION_ANALYTICS: (listing_id) => `/api/manager/listings/${listing_id}/conversation_analytics`,
  PROSPECT_ANALYTICS: (listing_id) => `/api/manager/listings/${listing_id}/prospect_analytics`,
  PROSPECT_GUEST_CARDS: '/api/manager/prospects_guest_cards',
  PROSPECT_ACTIVITY_FEED: (filters = {}) => `/api/manager/prospect_activity?${createGenericQueryString(filters)}`,
  UNREAD_ACTIVITY_FEED: '/api/manager/prospect_activity/unread_notifications',
  CREATE_COMMENT: (prospect_id) => `/api/manager/prospects/${prospect_id}/create_comment`,
  UPDATE_COMMENT: (prospect_id, comment_id) => `/api/manager/prospects/${prospect_id}/update_comment/${comment_id}`,
  DELETE_COMMENT: (prospect_id, comment_id) => `/api/manager/prospects/${prospect_id}/delete_comment/${comment_id}`,
  PROSPECT_LANDING_PAGE: (prospect_id) => `/api/manager/prospect_landing_page/${prospect_id}`,

  // LEADS
  GET_PROPERTY_LEADS: (property_id) => `/api/manager/listings/${property_id}/leads`,
  FETCH_OPEN_LEADS: (property_id, filters = {}) => `/api/manager/listings/${property_id}/leads/open_leads?${createGenericQueryString(
    filters,
  )}`,
  FETCH_ACTIVE_LEADS: (property_id, filters = {}) => `/api/manager/listings/${property_id}/leads/active_leads?${createGenericQueryString(
    filters,
  )}`,
  FETCH_MOVED_IN_LEADS: (property_id, filters = {}) => `/api/manager/listings/${property_id}/leads/moved_in_leads?${createGenericQueryString(
    filters,
  )}`,
  FETCH_DENIED_OR_LOST: (property_id, filters = {}) => `/api/manager/listings/${property_id}/leads/denied_lost_leads?${createGenericQueryString(
    filters,
  )}`,
  UPDATE_PROPERTY_LEADS: (property_id, prospect_id) => `/api/manager/listings/${property_id}/leads/${prospect_id}`,
  GET_SENDGRID_TEMPLATE: (templateId) => `https://api.sendgrid.com/v3/templates/${templateId}`,

  // REPORTS
  FETCH_REPORTS: (manager_id) => `/api/manager/manager_users/${manager_id}/reports`,
  FETCH_REPORT_BY_ID: (report_id, manager_id) => `/api/manager/manager_users/${manager_id}/${report_id}?timezone=${getTimeZone()}`,
  TOPIC_GUIDE: (searchQuery) => `/api/manager/topic_guides?search=${searchQuery}`,

  // NEIGHBOR NURTURE
  NEIGHBOR_NURTURE_PROPERTY: (id) => `/api/manager/neighbor_nurture/listings/${id}`,
  NEIGHBOR_NURTURE_PROPERTIES: (filters = {}) => `/api/manager/neighbor_nurture/listings${getField(
    filters,
  )}?${createNeighborNurtureQueryString(filters)}`,
  NEIGHBOR_NURTURE_LEAD_SOURCES: (filters = {}) => `/api/manager/neighbor_nurture/listings/lead_sources${getField(
    filters,
  )}?${createGenericQueryString(filters)}`,
  NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCE: (listing_id, lead_source_id) => `/api/manager/neighbor_nurture/listings/${listing_id}/lead_sources/${lead_source_id}`,
  NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCES: (listing_id, filters = {}) => `/api/manager/neighbor_nurture/listings/${listing_id}/lead_sources${getField(
    filters,
  )}?${createGenericQueryString(filters)}`,
  NEIGHBOR_NURTURE_PROPERTY_MANAGER_LEAD_SOURCE: (lead_source_id) => `/api/manager/neighbor_nurture/listings/lead_sources/${lead_source_id}`,
  NEIGHBOR_NURTURE_GLOBAL_OVERRIDE:
    '/api/manager/neighbor_nurture/global_override',

  // PAYMENTS METHODS
  PAYMENT_METHODS: '/api/manager/payment_methods',
  PAYMENT_METHOD: (id) => `/api/manager/payment_methods/${id}`,
  FETCH_PLAID_INTENT_TOKEN: '/api/manager/payment_methods/create_link_token',
  FETCH_STRIPE_INTENT_TOKEN:
    'api/manager/payment_methods/create_card_payment_intent',
  PROCESS_PAYMENT: '/api/manager/reward_checkouts',

  // TRANSACTIONS
  TRANSACTIONS: (filters = {}) => `/api/manager/transactions?${createGenericQueryString(filters)}`,
  TRANSACTION: (id) => `/api/manager/transactions/${id}`,

  // BULK UPDATES
  DOWNLOAD_BULK_UPDATE_TEMPLATE: (managementCompanyId) => `api/manager/property_managers/${managementCompanyId}/bulk_updates/create_template`,
  UPLOAD_BULK_UPDATE_TEMPLATE: (managementCompanyId) => `api/manager/property_managers/${managementCompanyId}/bulk_updates/validate_template`,
  APPLY_BULK_UPDATE_CHANGES: (managementCompanyId) => `api/manager/property_managers/${managementCompanyId}/bulk_updates/apply_template`,
};
