import actionTypes from '../constants/actionTypes';
import { sortListByKey } from '../constants/misc';

const initialBulkUpdatesState = {
  managementCompanies: {
    loading: true,
    data: [],
    error: null,
  },
  updateTemplate: {
    loading: true,
    data: [],
    error: null,
  },
  uploadedTemplate: {
    loading: false,
    error: null,
    validations: {},
    validation_hash: '',
    listings: [],
  },
};

export const bulkUpdates = (state = initialBulkUpdatesState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROPERTY_MANAGEMENT_COMPANIES_LOADING:
      return {
        ...state,
        managementCompanies: {
          ...state.managementCompanies,
          error: null,
          loading: true,
        },
      };

    case actionTypes.FETCH_PROPERTY_MANAGEMENT_COMPANIES_SUCCESS:
      return {
        ...state,
        managementCompanies: {
          ...state.managementCompanies,
          data: action.data.sort(sortListByKey('name')),
          error: null,
          loading: false,
        },
      };
    case actionTypes.FETCH_PROPERTY_MANAGEMENT_COMPANIES_FAILURE:
      return {
        ...state,
        managementCompanies: {
          ...state.managementCompanies,
          error: null,
          loading: false,
        },
      };
    case actionTypes.FETCH_PROPERTY_BULK_UPDATE_TEMPLATE_LOADING:
      return {
        ...state,
        updateTemplate: {
          ...state.updateTemplate,
          error: null,
          loading: true,
        },
      };

    case actionTypes.FETCH_PROPERTY_BULK_UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        updateTemplate: {
          ...state.updateTemplate,
          data: [...state.updateTemplate.data, action.data],
          error: null,
          loading: false,
        },
      };
    case actionTypes.FETCH_PROPERTY_BULK_UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,
        updateTemplate: {
          ...state.updateTemplate,
          error: null,
          loading: false,
        },
      };
    case actionTypes.UPLOAD_PROPERTY_BULK_UPDATE_TEMPLATE_LOADING:
    case actionTypes.APPLY_BULK_UPDATE_CHANGES_LOADING:
      return {
        ...state,
        uploadedTemplate: {
          ...state.uploadedTemplate,
          error: null,
          loading: true,
        },
      };

    case actionTypes.UPLOAD_PROPERTY_BULK_UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadedTemplate: {
          ...state.uploadedTemplate,
          ...action.data,
          error: null,
          loading: false,
        },
      };
    case actionTypes.UPLOAD_PROPERTY_BULK_UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,
        uploadedTemplate: {
          ...state.uploadedTemplate,
          error: null,
          loading: false,
        },
      };

    case actionTypes.APPLY_BULK_UPDATE_CHANGES_SUCCESS:
      return {
        ...state,
        uploadedTemplate: {
          ...state.uploadedTemplate,
          ...initialBulkUpdatesState.uploadedTemplate,
          error: null,
          loading: false,
        },
      };
    case actionTypes.APPLY_BULK_UPDATE_CHANGES_FAILURE:
      return {
        ...state,
        uploadedTemplate: {
          ...state.uploadedTemplate,
          ...action.data,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};
