/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import querystring from 'query-string';
import { withRouter } from 'react-router-dom';
import { rewardsv2Enabled } from '../../helpers/features';

export default function preventAuthenticatedUser(ComposedComponent) {
  class AuthRoute extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    static getDerivedStateFromProps(props) {
      if (props.user.authenticated) {
        const queries = querystring.parse(props.history.location.search);
        const { redirect, ...params } = queries;

        if (redirect) {
          const search = querystring.stringify(params);
          props.history.push({
            pathname: redirect,
            search: search ? `?${search}` : '',
            state: props.location.state,
          });
        } else if (rewardsv2Enabled()) {
          props.history.push('/rewards');
        } else {
          props.history.push('/properties');
        }

        return null;
      }
      return props;
    }

    render() {
      return <ComposedComponent {...this.props} {...this.state} />;
    }
  }

  const mapStateToProps = ({ user }) => ({ user });
  return withRouter(connect(mapStateToProps, null)(AuthRoute));
}
