import React from 'react';
import { Row } from 'antd';
import { bugsnagReactClient } from '../../helpers/bugsnag';

const ErrorBugsnagBoundary = bugsnagReactClient
  .getPlugin('react')
  .createErrorBoundary(React);

const FallbackUI = () => (
  <Row
    className="errorboundary__wrapper"
    type="flex"
    justify="center"
    align="middle"
  >
    <h1>An unexpected error occured</h1>
    <p>
      Kindly refresh your browser. If error persists please send us a message
      at support@rentgrata.com.
    </p>
  </Row>
);

export default function ErrorBoundary({ children }) {
  return (
    <ErrorBugsnagBoundary
      notifyReleaseStages={['production', 'staging']}
      FallbackComponent={FallbackUI}
    >
      {children}
    </ErrorBugsnagBoundary>
  );
}
