import actionTypes from '../constants/actionTypes';

const initialRewardDetailsState = {
  rewards: {
    data: [],
    error: null,
    loading: false,
  },
  individualRewards: {
    data: [],
    error: null,
    loading: false,
  },
  rewardDetailsStatus: null,
  showDisabledRewardReason: null,
  selectedRewards: [],
  enableReward: {
    loading: false, error: null,
  },
  disableReward: {
    loading: false, error: null,
  },
};

export const rewardDetails = (state = initialRewardDetailsState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_REWARD_DETAILS_STATUS:
      return { ...state, rewardDetailsStatus: action.payload };
    case actionTypes.UPDATE_DISABLE_REWARD_REASON:
      return { ...state, showDisabledRewardReason: action.payload };
    case actionTypes.FETCH_REWARD_DETAILS_LOADING:
      return {
        ...state,
        rewards: {
          ...state.rewards,
          error: null,
          loading: true,
        },
      };
    case actionTypes.FETCH_REWARD_DETAILS_SUCCESS:
      return {
        ...state,
        rewards: {
          data: action.rewards,
          error: null,
          loading: false,
        },
      };
    case actionTypes.FETCH_REWARD_DETAILS_FAILURE:
      return {
        ...state,
        rewards: {
          ...state.rewards,
          data: [],
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.FETCH_INDIVIDUAL_REWARD_DETAILS_LOADING:
      return {
        ...state,
        individualRewards: {
          ...state.individualRewards,
          error: null,
          loading: true,
        },
      };
    case actionTypes.FETCH_INDIVIDUAL_REWARD_DETAILS_SUCCESS:
      return {
        ...state,
        individualRewards: {
          data: action.rewards,
          error: null,
          loading: false,
        },
      };
    case actionTypes.FETCH_INDIVIDUAL_REWARD_DETAILS_FAILURE:
      return {
        ...state,
        individualRewards: {
          ...state.individualRewards,
          data: [],
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.UPDATE_SELECTED_REWARDS:
      return { ...state, selectedRewards: action.payload };
    case actionTypes.ENABLE_REWARDS_FAILURE:
      return {
        ...state,
        enableReward: {
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.ENABLE_REWARDS_LOADING:
      return {
        ...state,
        enableReward: {
          error: null,
          loading: true,
        },
      };
    case actionTypes.ENABLE_REWARDS_SUCCESS:
      return {
        ...state,
        enableReward: {
          error: null,
          loading: false,
        },
      };
    case actionTypes.DISABLE_REWARDS_FAILURE:
      return {
        ...state,
        disableReward: {
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.DISABLE_REWARDS_LOADING:
      return {
        ...state,
        disableReward: {
          error: null,
          loading: true,
        },
      };
    case actionTypes.DISABLE_REWARDS_SUCCESS:
      return {
        ...state,
        disableReward: {
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};
