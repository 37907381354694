import actionTypes from '../constants/actionTypes';

const initialUserState = {
  role: null,
  authenticated: false,
  image_url: {
    thumb: {},
    url: '',
  },
  avatarUploadState: null,
  message: '',
  passwordCreated: false,
  passwordVerified: false,
};

export const user = (state = initialUserState, action) => {
  switch (action.type) {
    case actionTypes.SIGNING_IN_SUCCESS:
      return {

        ...state,
        ...action.data,
        authenticated: true,
      };
    case actionTypes.SIGNING_IN_FAILURE:
      return { ...state };
    case actionTypes.SIGN_OUT_SUCCESS:
      return { ...initialUserState };
    case actionTypes.CURRENT_USER_SUCCESS:
      return {

        ...state,
        ...action.data,
        authenticated: true,
      };
    case actionTypes.CURRENT_USER_FAILURE:
      return { ...state };
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return { ...state, ...action.data };
    case actionTypes.POST_AVATAR_SUCCESS:
      return { ...state,
        image_url: action.data,
        avatarUploadState: action.type };
    case actionTypes.POST_AVATAR_ERROR:
      return { ...state, avatarUploadState: action.type };
    case actionTypes.RESET_AVATAR_UPLOAD_STATUS:
      return { ...state, avatarUploadState: null };
    case actionTypes.CREATE_PASSWORD_FAILURE:
      return { ...state,
        message: action.error,
        passwordCreated: false };
    case actionTypes.CREATE_PASSWORD_SUCCESS:
      return { ...state, passwordCreated: true };
    case actionTypes.VERIFY_PASSWORD_SUCCESS:
      return { ...state, passwordVerified: true };
    case actionTypes.VERIFY_PASSWORD_FAILURE:
      return { ...state,
        message: action.error.data,
        passwordVerified: false };

    default:
      return state;
  }
};
