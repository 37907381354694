import actionTypes from '../constants/actionTypes';

const transactionInitialState = {
  data: [],
  error: null,
  loading: false,
  totalCount: 0,
  currentPage: 1,
};

export const transactions = (state = transactionInitialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSACTIONS_LOADING:
      return {
        ...state,
        data: [],
        error: null,
        loading: true,
        totalCount: 0,
        currentPage: 1,
      };
    case actionTypes.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload?.transactions,
        error: null,
        loading: false,
        totalCount: action.payload?.total_count,
        currentPage: action.payload?.current_page,
      };
    case actionTypes.FETCH_TRANSACTIONS_FAILURE:
      return {
        ...state,
        data: [],
        error: action.error,
        loading: false,
        totalCount: 0,
        currentPage: 1,
      };
    default:
      return state;
  }
};

const transactionDetailsInitialState = {
  data: transactionInitialState.data[0],
  error: null,
  loading: false,
};

export const transactionDetails = (
  state = transactionDetailsInitialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSACTION_DETAILS_LOADING:
      return {
        ...state,
        data: {},
        error: null,
        loading: true,
      };
    case actionTypes.FETCH_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false,
      };
    case actionTypes.FETCH_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        data: {},
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
