import ReactGA from 'react-ga4';

const isProduction = process.env.NODE_ENV === 'production';
const gaAccountId = process.env.REACT_APP_GA4_ANALYTICS_ID;

export default class AnalyticsGA4 {
  static userId = null;

  static init(userId) {
    ReactGA.initialize(gaAccountId, { gtagOptions: { send_page_view: false }});

    this.userId = userId;
    if (userId) ReactGA.set({'user_properties': { 'user_id': userId }});
  }

  static setUser(userId) {
    ReactGA.set({'user_properties': { 'user_id': userId }});
  }

  static setDimensions(dimensions) {
    ReactGA.set({'user_properties': dimensions });
  }

  static setMetric(metric) {
    ReactGA.set({'metric': metric});
  }

  static pageView(pagePath, customParams={}) {
    if (!pagePath) throw Error('missing analytics page path');
    ReactGA.event('page_view', { 'page_location': pagePath, ...customParams });
  }

  static modalView(modalName, customParams={}) {
    if (!modalName) throw Error('missing analytics modal name');
    ReactGA.event('page_view', { 'page_title': modalName, ...customParams });
  }

  static event(event) {
    if (!event) throw Error('missing event object');
    if (!event.category || !event.action) {
      throw Error(`missing an event property: ${JSON.stringify(event)}`);
    }

    const value = event.value || this.userId;
    ReactGA.send({
      'hitType': "event",
      'eventCategory': event.category,
      'eventAction': event.action,
      'eventValue': value,
      ...event.dimensions
    });
  }
}
