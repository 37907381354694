import React, { Suspense } from 'react';
import Loader from '../Loader';
import { Row } from 'antd';

function RouteSuspenseFallbackUI(props) {
  return (
    <Row id="suspense-fallback-ui-wrapper">
      <Loader />
    </Row>
  );
}

function RouteSuspense(LazyComponent) {
  return (props) => {
    return (
      <Suspense maxDuration={1000} fallback={<RouteSuspenseFallbackUI />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  }
};

export default RouteSuspense;


