import { ROUTES } from '../constants/misc';
import client, { xhr } from '../helpers/httpClient';

export default {
  signIn(user_params) {
    return client.post(ROUTES.SIGN_IN, user_params);
  },

  resetPassword(user_params) {
    return client.post(ROUTES.RESET_PASSWORD, user_params);
  },

  createPassword(passwords) {
    return client.post(ROUTES.CREATE_NEW_PASSWORD, (passwords));
  },

  updatePassword(passwords) {
    return client.put(ROUTES.UPDATE_PASSWORD, (passwords));
  },

  fetchCurrentUser() {
    return client.get(ROUTES.CURRENT_USER);
  },

  updateProfile(userId, user_params) {
    return client.put(ROUTES.UPDATE_PROFILE(userId), user_params);
  },

  inviteUser(details) {
    return client.post(ROUTES.USER_INVITATIONS, details);
  },

  postAvatar(payload) {
    return xhr.post(ROUTES.POST_AVATAR, payload);
  },

  cancelInvite(userId) {
    return client.delete(ROUTES.CANCEL_USER_INVITE(userId));
  },

  cancelInactiveUserInvite(listingId, params) {
    return client.put(ROUTES.CANCEL_INACTIVE_USER_INVITE(listingId), params);
  },

  removePropertyManagerUser(user_id) {
    return client.delete(ROUTES.PROPERTY_MANAGER_USER(user_id));
  },

  verifyDefaultPassword(default_password) {
    return client.get(ROUTES.VERIFY_DEFAULT_PASSWORD(default_password));
  },

  refreshToken(token) {
    return client.put(ROUTES.REFRESH_TOKEN(token), { token });
  },

  inviteTeam(listingId, users) {
    return client.post(ROUTES.INVITE_TEAM(), {
      invite_users: users,
      property_id: listingId,
    });
  },

  reInviteUser(params) {
    return client.put(ROUTES.RE_INVITE_USER(), params);
  },

  fetchUserInvitations(listingId) {
    return client.get(ROUTES.FETCH_USER_INVITATIONS(listingId));
  },
};
