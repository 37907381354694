/* eslint-disable no-case-declarations */
import actionTypes from '../constants/actionTypes';

const initialRewardsData = {
  rewards: {
    data: [],
    error: null,
    loading: false,
    totalPages: 0,
    currentPage: 1,
  },
  rewards_stats: {
    outstanding_amount: null,
    error: null,
    loading: false,
  },
  listings: {
    data: [],
    error: null,
    loading: false,
    totalPages: 0,
    currentPage: 1,
  },
};

export const rewards = (state = initialRewardsData, action) => {
  let rewardIndex = 0;
  let rewardsObject;

  switch (action.type) {
    case actionTypes.FETCH_ALL_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: {
          ...state.listings,
          data: action.data,
          error: null,
          loading: false,
        },
      };
    case actionTypes.FETCH_ALL_LISTINGS_FAILURE:
      return {
        ...state,
        listings: {
          ...state.listings,
          data: [],
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.FETCH_ALL_LISTINGS_REWARDS_LOADING:
      return {
        ...state,
        rewards: {
          ...state.rewards,
          error: null,
          loading: true,
        },
      };
    case actionTypes.FETCH_RGPAY_REWARDS_STATS_LOADING:
      return {
        ...state,
        rewards_stats: {
          outstanding_amount: state.rewards_stats.outstanding_amount,
          error: null,
          loading: true,
        } };
    case actionTypes.FETCH_ALL_LISTINGS_REWARDS_STATS_LOADING:
      return { ...state, // rewards: {
        //   ...state.rewards,
        //   error: null,
        //   loading: true,
        // },
        rewards_stats: {
          error: null,
          loading: true,
        } };
    case actionTypes.FETCH_RGPAY_REWARDS_LOADING:
      return { ...state,
        rewards: {
          ...state.rewards,
          error: null,
          loading: true,
          totalPages: 0,
          currentPage: 1,
        },
      };
    case actionTypes.FETCH_ALL_LISTINGS_REWARDS_SUCCESS:
      return {
        ...state,
        rewards: {
          ...state.rewards,
          data: action.data,
          error: null,
          loading: false,
          totalPages: action.totalPages,
          currentPage: action.currentPage,
        },
      };
    case actionTypes.FETCH_RGPAY_REWARDS_STATS_SUCCESS:
      return {
        ...state,
        rewards_stats: {
          outstanding_amount: action.data.outstanding_amount,
          error: null,
          loading: false,
        } };

    case actionTypes.FETCH_ALL_LISTINGS_REWARDS_STATS_SUCCESS:
      return { ...state, // rewards: {
        //   ...state.rewards,
        // },
        rewards_stats: {
          outstanding_amount: action.data.outstanding_amount,
          error: null,
          loading: false,
        } };

    case actionTypes.FETCH_RGPAY_REWARDS_SUCCESS:
      return { ...state,
        rewards: {
          ...state.rewards,
          data: action.data.rewards,
          error: null,
          loading: false,
          totalPages: action.data.totalPages,
          currentPage: action.data.currentPage,
        },
      };
    case actionTypes.FETCH_ALL_LISTINGS_REWARDS_FAILURE:
      return {
        ...state,
        rewards: {
          ...state.rewards,
          data: [],
          error: action.error,
          loading: false,
          totalPages: 0,
          currentPage: 1,
        } };
    case actionTypes.FETCH_RGPAY_REWARDS_STATS_FAILURE:
      return { ...state,
        rewards_stats: {
          error: action.error,
          loading: false,
        } };
    case actionTypes.FETCH_ALL_LISTINGS_REWARDS_STATS_FAILURE:
      return { ...state,
        rewards_stats: {
          outstanding_amount: null,
          error: action.error,
          loading: false,
        } };
    case actionTypes.FETCH_RGPAY_REWARDS_FAILURE:
      return { ...state,
        rewards: {
          ...state.rewards,
          data: [],
          error: action.error,
          loading: false,
          totalPages: 0,
          currentPage: 1,
        },
      };
    case actionTypes.UPDATE_RESIDENT_REWARDS_SUCCESS:
    case actionTypes.UPDATE_PROSPECT_REWARDS_SUCCESS:
      rewardIndex = 0;

      if (action.data) {
        rewardIndex = state.rewards.data.findIndex(
          (resident) => resident.reward_id === action.data.reward.reward_id,
        );
      }

      rewardsObject =
        rewardIndex >= 0
          ? {
            ...state.rewards,
            data: [
              ...state.rewards.data.slice(0, rewardIndex),
              {
                ...state.rewards.data[rewardIndex],
                ...action.data.reward,
              },
              ...state.rewards.data.slice(rewardIndex + 1),
            ],
            loading: false,
          }
          : { ...state.rewards };
      return { ...state, rewardsObject };
    case actionTypes.UPDATE_PROSPECT_REWARDS_FAILURE:
    case actionTypes.UPDATE_RESIDENT_REWARDS_FAILURE:
      return {
        ...state,
        rewards: {
          ...state.rewards,
          data: state.rewards.data,
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.SIGN_OUT_SUCCESS:
      return initialRewardsData;
    default:
      return state;
  }
};
