import ReactGA from 'react-ga';
import AnalyticsGA4 from './AnalyticsGA4';

const isProduction = process.env.NODE_ENV === 'production';
const gaAccountId = process.env.REACT_APP_UA_ANALYTICS_ID;

export default class Analytics {
  static userId = null;

  static init(userId) {
    ReactGA.initialize(gaAccountId, {
      debug: !isProduction,
      testMode: !isProduction,
    });

    AnalyticsGA4.init(userId);

    this.userId = userId;
    if (userId) {
      ReactGA.set({ userId });
      AnalyticsGA4.setUser(userId);
    }
  }

  static setUser(userId) {
    ReactGA.set({ userId });
    AnalyticsGA4.setUser(userId);
  }

  static setDimensions(dimensions) {
    ReactGA.set(dimensions);
  }

  static setMetric(metric) {
    ReactGA.set(metric);
  }

  static pageView(pagePath, customParams = {}) {
    if (!pagePath) throw Error('missing analytics page path');
    ReactGA.pageview(pagePath);
    AnalyticsGA4.pageView(pagePath, customParams);
  }

  static modalView(modalName, customParams = {}) {
    if (!modalName) throw Error('missing analytics modal name');
    ReactGA.modalview(modalName);
    AnalyticsGA4.modalView(modalName, customParams);
  }

  static event(event) {
    if (!event) throw Error('missing event object');
    if (!event.category || !event.action) {
      throw Error(`missing an event property: ${JSON.stringify(event)}`);
    }

    const value = event.value || this.userId;

    ReactGA.event({ ...event, value });
    AnalyticsGA4.event(event);
  }
}
