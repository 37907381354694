import actionTypes from '../constants/actionTypes';
import { serializePagination } from '../helpers/customMethods';

const initialLeadSourceState = {
  leadSources: {
    error: null,
    data: [],
    loading: false,
    totalPages: 0,
    currentPage: 1,
  },
  properties: {
    error: null,
    data: [],
    loading: false,
  },
};

export const leadSource = (state = initialLeadSourceState, action) => {
  switch (action.type) {
    case actionTypes.PAGE_LOADING:
      return { ...state, [action.key]: { ...state[action.key], loading: action.data } };
    case actionTypes.FETCH_NEIGHBOR_NURTURE_LEAD_SOURCES_SUCCESS:
      return {
        ...state,
        leadSources: {
          loading: false,
          data: action.data.lead_sources,
          ...serializePagination(action),
        },
        properties: {
          loading: false,
          data: action.data.listings,
        },
      };
    case actionTypes.FETCH_NEIGHBOR_NURTURE_LEAD_SOURCES_FAILURE:
      return {
        ...state,
        leadSources: { error: action.error, loading: false, data: [] },
        properties: { error: action.error, loading: false, data: [] },
      };
    case actionTypes.FETCH_NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCES_SUCCESS:
      return {
        ...state,
        leadSources: {
          loading: false,
          data: action.data.lead_sources,
          ...serializePagination(action),
        },
        properties: {
          loading: false,
          data: action.data.listings,
        },
      };
    case actionTypes.FETCH_NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCES_FAILURE:
      return {
        ...state,
        leadSources: { error: action.error, loading: false, data: [] },
        properties: { error: action.error, loading: false, data: [] },
      };
    case actionTypes.UPDATE_NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCE_SUCCESS: {
      const { leadSources } = { ...state };
      const data = leadSources.data;
      const { success, lead_source } = action.data;

      if (success && lead_source) {
        data[data.findIndex((source) => source.id === lead_source?.id)] = lead_source;
      }

      return {
        ...state,
        leadSources: { loading: false, data },
      };
    }
    case actionTypes.UPDATE_NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCE_FAILURE:
      return {
        ...state,
        leadSources: { error: action.error, loading: false },
      };
    case actionTypes.UPDATE_NEIGHBOR_NURTURE_PROPERTY_MANAGER_LEAD_SOURCE_SUCCESS: {
      const { leadSources } = { ...state };
      const data = leadSources.data;
      const { success, lead_source } = action.data;

      if (success && lead_source) {
        data[data.findIndex((source) => source.id === lead_source?.id)] = lead_source;
      }

      return {
        ...state,
        leadSources: { loading: false, data },
      };
    }
    case actionTypes.UPDATE_NEIGHBOR_NURTURE_PROPERTY_MANAGER_LEAD_SOURCE_FAILURE:
      return {
        ...state,
        leadSources: { error: action.error, loading: false },
      };
    default:
      return state;
  }
};
