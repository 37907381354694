/* eslint-disable key-spacing */
import { keyMirror } from '../helpers/customMethods';

const actionTypes = Object.freeze(keyMirror({
  // SIGN IN
  SIGNING_IN_SUCCESS                         : null,
  SIGNING_IN_FAILURE                         : null,

  // SIGN OUT
  SIGN_OUT_SUCCESS                           : null,

  // PASSSWORD RESET
  PASSWORD_RESET_SUCCESS                     : null,
  PASSWORD_RESET_FAILURE                     : null,

  // USER AVATAR
  POST_AVATAR_SUCCESS                        : null,
  POST_AVATAR_ERROR                          : null,
  RESET_AVATAR_UPLOAD_STATUS                 : null,

  // FETCH USER
  FETCH_USER_SUCCESS                         : null,
  FETCH_USER_FAILURE                         : null,

  // CURRENT USER
  CURRENT_USER_SUCCESS                       : null,
  CURRENT_USER_FAILURE                       : null,

  // CREATE PASSWORD
  CREATE_PASSWORD_SUCCESS                    : null,
  CREATE_PASSWORD_FAILURE                    : null,

  // UPDATE PASSWORD
  UPDATE_PASSWORD_SUCCESS                    : null,
  UPDATE_PASSWORD_FAILURE                    : null,

  // VERIFY PASSWORD
  VERIFY_PASSWORD_SUCCESS                    : null,
  VERIFY_PASSWORD_FAILURE                    : null,

  // INVITE USER
  INVITE_USER_SUCCESS                        : null,
  INVITE_USER_FAILURE                        : null,

  CANCEL_USER_INVITE_SUCCESS                 : null,
  CANCEL_USER_INVITE_FAILURE                 : null,

  CANCEL_INACTIVE_USER_INVITE_SUCCESS        : null,
  CANCEL_INACTIVE_USER_INVITE_FAILURE        : null,

  ADD_STRIPE_CARD_ACCOUNT_SUCCESS            : null,
  ADD_STRIPE_CARD_ACCOUNT_FAILURE            : null,

  ADD_BILLING_ADDRESS_SUCCESS                : null,
  ADD_BILLING_ADDRESS_FAILURE                : null,

  ADD_STRIPE_BANK_ACCOUNT_SUCCESS            : null,
  ADD_STRIPE_BANK_ACCOUNT_FAILURE            : null,

  REMOVE_STRIPE_ACCOUNT_SUCCESS              : null,
  REMOVE_STRIPE_ACCOUNT_FAILURE              : null,

  VERIFY_BANK_ACCOUNT_SUCCESS                : null,
  VERIFY_BANK_ACCOUNT_FAILURE                : null,

  FETCH_USER_BILLING_ACCOUNTS_SUCCESS        : null,
  FETCH_USER_BILLING_ACCOUNTS_FAILURE        : null,

  FETCH_PAYMENT_HISTORY_SUCCESS              : null,
  FETCH_PAYMENT_HISTORY_FAILURE              : null,

  EXPORT_PAYMENT_INVOICE_SUCCESS             : null,
  EXPORT_PAYMENT_INVOICE_FAILURE             : null,

  // UPDATE PROFILE
  UPDATE_PROFILE_SUCCESS                     : null,
  UPDATE_PROFILE_FAILURE                     : null,

  // REWARDS
  FETCH_REWARDS_SUCCESS                      : null,
  FETCH_REWARDS_FAILURE                      : null,

  // UPDATE PROSPECT REWARDS
  UPDATE_PROSPECT_REWARDS_SUCCESS            : null,
  UPDATE_PROSPECT_REWARDS_FAILURE            : null,
  UPDATE_RESIDENT_REWARDS_SUCCESS            : null,
  UPDATE_RESIDENT_REWARDS_FAILURE            : null,

  // FETCH RESIDENT REWARDS TABLE
  FETCH_RESIDENT_REWARDS_TABLE_SUCCESS       : null,
  FETCH_RESIDENT_REWARDS_TABLE_FAILURE       : null,

  // PROPERTIES/LISTINGS
  FETCH_PROPERTY_MANAGER_SUCCESS             : null,
  FETCH_PROPERTY_MANAGER_FAILURE             : null,

  FETCH_PROPERTIES_MANAGERS_SUCCESS          : null,
  FETCH_PROPERTIES_MANAGERS_FAILURE          : null,

  RESET_PROPERTIES                           : null,
  FETCH_PROPERTIES_SUCCESS                   : null,
  FETCH_PROPERTIES_FAILURE                   : null,

  FETCH_PROPERTY_SUCCESS                     : null,
  FETCH_PROPERTY_FAILURE                     : null,

  UPDATE_PROPERTY_SUCCESS                    : null,
  UPDATE_PROPERTY_FAILURE                    : null,

  FETCH_PROPERTY_USERS_SUCCESS               : null,
  FETCH_PROPERTY_USERS_FAILURE               : null,

  FETCH_PROPERTY_MANAGER_USERS_SUCCESS       : null,
  FETCH_PROPERTY_MANAGER_USERS_FAILURE       : null,

  FETCH_PROPERTY_MANAGER_USER_SUCCESS        : null,
  FETCH_PROPERTY_MANAGER_USER_FAILURE        : null,

  UPDATE_PROPERTY_MANAGER_USER_SUCCESS       : null,
  UPDATE_PROPERTY_MANAGER_USER_FAILURE       : null,

  SEARCH_MANAGER_USERS_SUCCESS               : null,
  SEARCH_MANAGER_USERS_FAILURE               : null,

  POST_MESSENGER_CONFIG_SUCCESS              : null,
  POST_MESSENGER_CONFIG_FAILURE              : null,

  REMOVE_PROPERTY_MANAGER_USER_SUCCESS       : null,
  REMOVE_PROPERTY_MANAGER_USER_FAILURE       : null,
  INVITE_PROPERTY_USER_SUCCESS               : null,

  FETCH_PROXIMITY_WORDS_SUCCESS              : null,
  FETCH_PROXIMITY_WORDS_FAILURE              : null,

  FETCH_RESIDENT_RESPONSES_SUCCESS           : null,
  FETCH_RESIDENT_RESPONSES_FAILURE           : null,

  FETCH_TOP_RESIDENTS_SUCCESS                : null,
  FETCH_TOP_RESIDENTS_FAILURE                : null,

  FETCH_MOVED_IN_INTERESTS_SUCCESS           : null,
  FETCH_MOVED_IN_INTERESTS_FAILURE           : null,

  FETCH_LOST_CONCERNS_SUCCESS                : null,
  FETCH_LOST_CONCERNS_FAILURE                : null,

  FETCH_REWARD_SNAPSHOT_SUCCESS              : null,
  FETCH_REWARD_SNAPSHOT_FAILURE              : null,
  INVITE_RESIDENTS_SUCCESS                   : null,
  INVITE_RESIDENTS_FAILURE                   : null,

  UPDATE_PROPERTY_BILLING_CONTACT_SUCCESS    : null,
  UPDATE_PROPERTY_BILLING_CONTACT_FAILURE    : null,

  SEND_WIDGET_INSTALLATION_INSTRUCTION_SUCCESS : null,
  SEND_WIDGET_INSTALLATION_INSTRUCTION_FAILURE : null,

  UPDATE_ANNOUNCE_WIDGET_SUCCESS               : null,
  UPDATE_ANNOUNCE_WIDGET_FAILURE               : null,

  UPDATE_PRINT_AND_MAKE_COPIES_SUCCESS         : null,
  UPDATE_PRINT_AND_MAKE_COPIES_FAILURE         : null,

  INVITE_TEAM_SUCCESS                          : null,
  INVITE_TEAM_FAILURE                          : null,

  RE_INVITE_USER_SUCCESS                       : null,
  RE_INVITE_USER_FAILURE                       : null,

  FETCH_USER_INVITATIONS_SUCCESS               : null,
  FETCH_USER_INVITATIONS_FAILURE               : null,

  ACTIVATE_SUBSCRIPTION_SUCCESS                : null,
  ACTIVATE_SUBSCRIPTION_FAILURE                : null,

  RESUME_SERVICE_SUCCESS                       : null,
  RESUME_SERVICE_FAILURE                       : null,

  RETRY_PAYMENT_SUCCESS                       : null,
  RETRY_PAYMENT_FAILURE                       : null,

  // NOTIFICATIONS
  FETCH_PROPERTY_NOTIFICAITONS_SUCCESS       : null,
  FETCH_PROPERTY_NOTIFICAITONS_FAILURE       : null,
  UPDATE_REWARDS_NOTIFICATION                : null,

  // RESIDENCIES
  FETCH_RESIDENCIES_SUCCESS                  : null,
  FETCH_RESIDENCIES_FAILURE                  : null,

  FETCH_RESIDENT_SUCCESS                     : null,
  FETCH_RESIDENT_FAILURE                     : null,

  REPORT_RESIDENT_SUCCESS                    : null,
  REPORT_RESIDENT_FAILURE                    : null,

  // PROSPECTS
  FETCH_PROPERTY_PROSPECTS_SUCCESS           : null,
  FETCH_PROPERTY_PROSPECTS_FAILURE           : null,

  FETCH_PROPERTY_ACTIVITY_FEED_SUCCESS       : null,
  FETCH_PROPERTY_ACTIVITY_FEED_FAILURE       : null,

  FETCH_ACTIVITY_FEED_UNREAD_SUCCESS         : null,
  FETCH_ACTIVITY_FEED_UNREAD_FAILURE         : null,

  FETCH_PROPERTY_PROSPECT_SUCCESS            : null,
  FETCH_PROPERTY_PROSPECT_FAILURE            : null,

  FETCH_PROSPECTS_SUCCESS                    : null,
  FETCH_PROSPECTS_FAILURE                    : null,

  CREATE_OR_FETCH_PROSPECT_GUEST_CARD_SUCCESS: null,
  CREATE_OR_FETCH_PROSPECT_GUEST_CARD_FAILURE: null,

  CREATE_PROSPECT_COMMENT_SUCCESS            : null,
  CREATE_PROSPECT_COMMENT_FAILURE            : null,

  UPDATE_PROSPECT_COMMENT_SUCCESS            : null,
  UPDATE_PROSPECT_COMMENT_FAILURE            : null,

  DELETE_PROSPECT_COMMENT_SUCCESS            : null,
  DELETE_PROSPECT_COMMENT__FAILURE           : null,

  // LEADS
  FETCH_LEADS_SUCCESS                        : null,
  FETCH_LEADS_FAILURE                        : null,
  FETCH_OPEN_LEADS_SUCCESS                   : null,
  FETCH_OPEN_LEADS_FAILURE                   : null,
  FETCH_ACTIVE_LEADS_SUCCESS                 : null,
  FETCH_ACTIVE_LEADS_FAILURE                 : null,
  FETCH_MOVED_IN_LEADS_SUCCESS               : null,
  FETCH_MOVED_IN_LEADS_FAILURE               : null,
  FETCH_DENIED_LOST_SUCCESS                  : null,
  FETCH_DENIED_LOST_FAILURE                  : null,
  UPDATE_LEAD_SUCCESS                        : null,
  UPDATE_LEAD_ERROR                          : null,
  SORT_LEADS_SUCCESS                         : null,
  LOADING_LEADS                              : null,

  FETCH_PROSPECT_SUCCESS                     : null,
  FETCH_PROSPECT_FAILURE                     : null,

  FETCH_CONVERSATIONS_ANALYTICS_SUCCESS      : null,
  FETCH_CONVERSATIONS_ANALYTICS_FAILURE      : null,

  FETCH_PROSPECT_SENTIMENT_SUCCESS           : null,
  FETCH_PROSPECT_SENTIMENT_FAILURE           : null,
  FETCH_RESIDENT_SENTIMENT_SUCCESS           : null,
  FETCH_RESIDENT_SENTIMENT_FAILURE           : null,

  FETCH_PROSPECTS_ANALYTICS_SUCCESS          : null,
  FETCH_PROSPECTS_ANALYTICS_FAILURE          : null,

  FETCH_RESIDENTS_ANALYTICS_SUCCESS          : null,
  FETCH_RESIDENTS_ANALYTICS_FAILURE          : null,

  // CONVERSATIONS
  FETCH_CONVERSATION_SUCCESS                 : null,
  FETCH_CONVERSATION_FAILURE                 : null,

  FETCH_RESIDENT_CONVERSATIONS_SUCCESS       : null,
  FETCH_RESIDENT_CONVERSATIONS_FAILURE       : null,

  // FLOORPLANS
  FETCH_FLOORPLANS_SUCCESS                   : null,
  FETCH_FLOORPLANS_FAILURE                   : null,

  // PAGINATION
  PAGE_LOADING                               : null,

  // REPORTS
  FETCH_REPORTS_LOADING                      : null,
  FETCH_REPORTS_SUCCESS                      : null,
  FETCH_REPORTS_FAILURE                      : null,

  FETCH_SELECTED_REPORT_LOADING              : null,
  FETCH_SELECTED_REPORT_SUCCESS              : null,
  FETCH_SELECTED_REPORT_FAILURE              : null,

  RESET_REPORT_DATA                          : null,

  // REWARDS
  FETCH_ALL_LISTINGS_REWARDS_LOADING         : null,
  FETCH_ALL_LISTINGS_REWARDS_SUCCESS         : null,
  FETCH_ALL_LISTINGS_REWARDS_FAILURE         : null,

  // RGPAY REWARDS
  FETCH_RGPAY_REWARDS_STATS_LOADING          : null,
  FETCH_RGPAY_REWARDS_STATS_SUCCESS          : null,
  FETCH_RGPAY_REWARDS_STATS_FAILURE          : null,
  FETCH_RGPAY_REWARDS_LOADING                : null,
  FETCH_RGPAY_REWARDS_SUCCESS                : null,
  FETCH_RGPAY_REWARDS_FAILURE                : null,
  FETCH_ALL_LISTINGS_REWARDS_STATS_LOADING   : null,
  FETCH_ALL_LISTINGS_REWARDS_STATS_SUCCESS   : null,
  FETCH_ALL_LISTINGS_REWARDS_STATS_FAILURE   : null,

  FETCH_ALL_LISTINGS_LOADING                 : null,
  FETCH_ALL_LISTINGS_SUCCESS                 : null,
  FETCH_ALL_LISTINGS_FAILURE                 : null,

  // NEIGHBOR NURTURE
  FETCH_NEIGHBOR_NURTURE_PROPERTIES_SUCCESS  : null,
  FETCH_NEIGHBOR_NURTURE_PROPERTIES_FAILURE  : null,

  UPDATE_NEIGHBOR_NURTURE_PROPERTY_SUCCESS   : null,
  UPDATE_NEIGHBOR_NURTURE_PROPERTY_FAILURE   : null,

  FETCH_NEIGHBOR_NURTURE_LEAD_SOURCES_SUCCESS: null,
  FETCH_NEIGHBOR_NURTURE_LEAD_SOURCES_FAILURE: null,

  FETCH_NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCES_SUCCESS: null,
  FETCH_NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCES_FAILURE: null,

  UPDATE_NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCE_SUCCESS: null,
  UPDATE_NEIGHBOR_NURTURE_PROPERTY_LEAD_SOURCE_FAILURE: null,

  UPDATE_NEIGHBOR_NURTURE_PROPERTY_MANAGER_LEAD_SOURCE_SUCCESS: null,
  UPDATE_NEIGHBOR_NURTURE_PROPERTY_MANAGER_LEAD_SOURCE_FAILURE: null,

  UPDATE_GLOBAL_OVERRIDE_NEIGHBOR_NURTURE_SUCCESS : null,
  UPDATE_GLOBAL_OVERRIDE_NEIGHBOR_NURTURE_FAILURE : null,

  // RGPAY REWARD DETAILS
  FETCH_REWARD_DETAILS_LOADING               : null,
  FETCH_REWARD_DETAILS_SUCCESS               : null,
  FETCH_REWARD_DETAILS_FAILURE               : null,
  FETCH_INDIVIDUAL_REWARD_DETAILS_LOADING    : null,
  FETCH_INDIVIDUAL_REWARD_DETAILS_SUCCESS    : null,
  FETCH_INDIVIDUAL_REWARD_DETAILS_FAILURE    : null,
  UPDATE_REWARD_DETAILS_STATUS               : null,
  UPDATE_DISABLE_REWARD_REASON               : null,
  UPDATE_SELECTED_REWARDS                    : null,
  ENABLE_REWARDS_LOADING                     : null,
  ENABLE_REWARDS_SUCCESS                     : null,
  ENABLE_REWARDS_FAILURE                     : null,
  DISABLE_REWARDS_LOADING                    : null,
  DISABLE_REWARDS_SUCCESS                    : null,
  DISABLE_REWARDS_FAILURE                    : null,

  MARK_REWARDED_LOADING                      : null,
  MARK_REWARDED_SUCCESS                      : null,
  MARK_REWARDED_FAILURE                      : null,

  // FETCH TRANSACTIONS
  FETCH_TRANSACTIONS_LOADING                 : null,
  FETCH_TRANSACTIONS_SUCCESS                 : null,
  FETCH_TRANSACTIONS_FAILURE                 : null,

  FETCH_TRANSACTION_DETAILS_LOADING          : null,
  FETCH_TRANSACTION_DETAILS_SUCCESS          : null,
  FETCH_TRANSACTION_DETAILS_FAILURE          : null,

  // FETCH PAYMENTS METHOD
  FETCH_PAYMENT_METHODS_LOADING              : null,
  FETCH_PAYMENT_METHODS_SUCCESS              : null,
  FETCH_PAYMENT_METHODS_FAILURE              : null,

  // UPDATE PAYMENTS METHOD
  UPDATE_PAYMENT_METHODS_LOADING             : null,
  UPDATE_PAYMENT_METHODS_SUCCESS             : null,
  UPDATE_PAYMENT_METHODS_FAILURE             : null,

  ADD_ONE_TIME_PAYMENT_METHOD                : null,

  // DELETE PAYMENTS METHOD
  DELETE_PAYMENT_METHODS_LOADING             : null,
  DELETE_PAYMENT_METHODS_SUCCESS             : null,
  DELETE_PAYMENT_METHODS_FAILURE             : null,

  // PROCESS PAYMENT
  PROCESS_PAYMENT_LOADING                    : null,
  PROCESS_PAYMENT_SUCCESS                    : null,
  PROCESS_PAYMENT_FAILURE                    : null,

  // PROCESS PAYMENT
  FETCH_PROPERTY_MANAGEMENT_COMPANIES_LOADING : null,
  FETCH_PROPERTY_MANAGEMENT_COMPANIES_SUCCESS : null,
  FETCH_PROPERTY_MANAGEMENT_COMPANIES_FAILURE : null,

  FETCH_PROPERTY_BULK_UPDATE_TEMPLATE_LOADING : null,
  FETCH_PROPERTY_BULK_UPDATE_TEMPLATE_SUCCESS : null,
  FETCH_PROPERTY_BULK_UPDATE_TEMPLATE_FAILURE : null,

  UPLOAD_PROPERTY_BULK_UPDATE_TEMPLATE_LOADING : null,
  UPLOAD_PROPERTY_BULK_UPDATE_TEMPLATE_SUCCESS : null,
  UPLOAD_PROPERTY_BULK_UPDATE_TEMPLATE_FAILURE : null,

  APPLY_BULK_UPDATE_CHANGES_LOADING            : null,
  APPLY_BULK_UPDATE_CHANGES_SUCCESS            : null,
  APPLY_BULK_UPDATE_CHANGES_FAILURE            : null,
}));

export default actionTypes;
