import axios from 'axios';
import XHR from './XHR';

const config = {
  baseURL: `${process.env.REACT_APP_API_URL}`,
};

const axiosClient = axios.create(config);

function setAuthorizationToken(token) {
  const defaultHeaders = this.defaults.headers.common || {};

  if (token) {
    defaultHeaders.Authorization = `Bearer ${token}`;
  } else {
    delete defaultHeaders.Authorization;
  }
}

/*
  The choice of using XHR was made because
  Axios lacks support for explicit request cancellation during
  searches at the time. XHR implementations are typically used for search,
  filter, and similar requests where the most recent request takes priority over its predecessors.
*/
const xhr = Object.assign(new XHR(config), {
  setAuthorizationToken,
});

const httpClient = Object.assign(axiosClient, {
  setAuthorizationToken,
});

export default httpClient;
export { xhr };
