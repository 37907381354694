import actionTypes from "../constants/actionTypes";

const initialConversationsState = {
  data: [],
  totalPages: 0,
  currentPage: 1,
  loading: true,
  error: null,
};

export const conversations = (state = initialConversationsState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONVERSATION_SUCCESS:
      return Object.assign({}, state, { ...action, error: null,  loading: false });
    case actionTypes.FETCH_CONVERSATION_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: false,
      });
    default:
      return state;
  }
};