import { combineReducers } from 'redux';
import { user } from './userReducer';
import { property } from './propertyReducer';
import { prospect } from './prospectReducer';
import { residency } from './residencyReducer';
import { leads } from './leadsReducer';
import { conversations } from './conversationReducer';
import { reports } from './reportsReducer';
import { leadSource } from './leadSourceReducer';
import { rewards } from './rewardsReducer';
import { rewardDetails } from './rewardsDetailsReducer';
import { transactionDetails, transactions } from './transactionReducer';
import { paymentMethods, processPayment } from './paymentsReducers';
import { bulkUpdates } from './bulkUpdatesReducer';

export default combineReducers({
  user,
  leads,
  property,
  prospect,
  residency,
  conversations,
  reports,
  rewards,
  leadSource,
  rewardDetails,
  transactions,
  transactionDetails,
  paymentMethods,
  processPayment,
  bulkUpdates,
});
