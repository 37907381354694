import actionTypes from "../constants/actionTypes";
import groupBy from "lodash/fp/groupBy";
import mapValues from "lodash/fp/mapValues";
import filter from "lodash/fp/filter";
import uniqBy from "lodash/fp/uniqBy";
import flatten from "lodash/fp/flatten";
import compose from "lodash/fp/compose";
import {
  serializePagination,
  serializeActivityFeedPagination,
} from "../helpers/customMethods";

const initialProspectState = {
  prospects: {
    error: null,
    isSubmitted: false,
    data: [],
    suggestions: [],
    totalPages: 0,
    currentPage: 1,
    loading: false,
  },
  property_prospects: {
    data: [],
    error: null,
    loading: true,
    totalPages: 0,
    currentPage: 1,
  },
  property_prospect: {
    data: [],
    error: null,
  },
  conversationsAnalytics: {
    data: {
      new_conversations_count: 0,
      new_conversations_rate: [],
    },
  },
  prospectsAnalytics: {
    data: {
      new_prospect_count: 0,
      new_prospect_rate: [],
    },
    error: null,
  },
  prospectActivityFeed: {
    data: [],
    error: null,
    totalPages: 0,
    currentPage: 1,
  },
  unreadProspectActivity: {
    data: "",
    error: null,
  },
  prospects_rewards: {
    data: [],
    error: null,
    loading: false,
    totalPages: 0,
    currentPage: 1,
  },
};

const pickUserWithMultipleListings = (users) => {
  const uniqListing = uniqBy("listing_id");
  const greaterThanOneListing = (listings) =>
    listings.length > 1 ? listings : null;
  const groupedByUserName = groupBy("user_name");
  const listingPerUser = mapValues(compose(greaterThanOneListing, uniqListing));
  const removeNulls = filter((d) => d !== null);
  const composer = compose(
    flatten,
    removeNulls,
    listingPerUser,
    groupedByUserName
  );

  return composer(users) || [];
};

export const prospect = (state = initialProspectState, action) => {
  switch (action.type) {
    case actionTypes.PAGE_LOADING:
      return Object.assign({}, state, {
        [action.key]: { ...state[action.key], loading: action.data },
      });
    case actionTypes.FETCH_PROPERTY_PROSPECTS_SUCCESS:
      return Object.assign({}, state, {
        property_prospects: {
          data: action.data,
          error: null,
          loading: false,
          ...serializePagination(action),
        },
      });
    case actionTypes.FETCH_PROPERTY_PROSPECTS_FAILURE:
      return Object.assign({}, state, {
        property_prospects: { data: [], error: action.error, loading: false },
      });
    case actionTypes.FETCH_PROPERTY_PROSPECT_SUCCESS:
      return Object.assign({}, state, {
        property_prospect: { data: action.data, error: null, loading: false },
      });
    case actionTypes.FETCH_PROPERTY_PROSPECT_FAILURE:
      return Object.assign({}, state, {
        property_prospect: { data: [], error: action.error, loading: false },
      });
    case actionTypes.FETCH_PROPERTY_ACTIVITY_FEED_SUCCESS:
      return Object.assign({}, state, {
        prospectActivityFeed: {
          data: action.data,
          error: null,
          ...serializeActivityFeedPagination(action),
        },
      });
    case actionTypes.FETCH_PROPERTY_ACTIVITY_FEED_FAILURE:
      return Object.assign({}, state, {
        prospectActivityFeed: { data: [], error: action.error },
      });
    case actionTypes.FETCH_ACTIVITY_FEED_UNREAD_SUCCESS:
      return Object.assign({}, state, {
        unreadProspectActivity: { data: action.data, error: null },
      });
    case actionTypes.FETCH_ACTIVITY_FEED_UNREAD_FAILURE:
      return Object.assign({}, state, {
        unreadProspectActivity: { data: "", error: action.error },
      });
    case actionTypes.FETCH_PROSPECTS_SUCCESS:
      return Object.assign({}, state, {
        prospects: {
          data: action.isSubmitted
            ? pickUserWithMultipleListings(action.data)
            : [],
          isSubmitted: action.isSubmitted,
          error: null,
          suggestions: action.data || action.email,
          loading: false,
        },
      });
    case actionTypes.FETCH_PROSPECTS_FAILURE:
      return Object.assign({}, state, {
        prospects: Object.assign(
          {},
          {
            error: action.error,
            suggestions: [],
            data: [],
            loading: false,
          }
        ),
      });
    case actionTypes.FETCH_CONVERSATIONS_ANALYTICS_SUCCESS:
      return Object.assign({}, state, {
        conversationsAnalytics: {
          data: action.data,
          error: null,
        },
      });
    case actionTypes.FETCH_CONVERSATIONS_ANALYTICS_FAILURE:
      return Object.assign({}, state, {
        conversationsAnalytics: {
          data: {
            new_conversations_count: 0,
            new_conversations_rate: [],
          },
          error: action.error,
        },
      });
    case actionTypes.FETCH_PROSPECTS_ANALYTICS_SUCCESS:
      return Object.assign({}, state, {
        prospectsAnalytics: {
          data: action.data,
          error: null,
        },
      });
    case actionTypes.FETCH_PROSPECTS_ANALYTICS_FAILURE:
      return Object.assign({}, state, {
        prospectsAnalytics: {
          data: {
            new_prospect_count: 0,
            new_prospect_rate: [],
          },
          error: action.error,
        },
      });
    case actionTypes.CREATE_OR_FETCH_PROSPECT_GUEST_CARD_SUCCESS:
      return Object.assign({}, state, {
        property_prospect: { data: action.data, error: null },
      });
    case actionTypes.FETCH_REWARDS_SUCCESS:
      return Object.assign({}, state, {
        prospects_rewards: {
          data: action.data,
          error: null,
          loading: false,
          ...serializePagination(action),
        },
      });
    case actionTypes.FETCH_REWARDS_FAILURE:
      return Object.assign({}, state, {
        prospects_rewards: { data: [], error: action.error, loading: false },
      });
    case actionTypes.UPDATE_RESIDENT_REWARDS_SUCCESS:
    case actionTypes.UPDATE_PROSPECT_REWARDS_SUCCESS:
      let residentIndex = state.prospects_rewards.data.findIndex(
        (resident) =>
          resident.reward_id === action.data.reward.reward_id &&
          resident.listing_id === action.data.reward.listing_id
      );
      if (residentIndex >= 0) {
        return {
          ...state,
          property_prospect: {
            data: { ...state.property_prospect.data, ...action.data.prospect },
          },
          prospects_rewards: {
            data: [
              ...state.prospects_rewards.data.slice(0, residentIndex),
              {
                ...action.data.reward,
                status_changed_at:
                  state.prospects_rewards.data[residentIndex].status_changed_at,
              },
              ...state.prospects_rewards.data.slice(residentIndex + 1),
            ],
            totalPages: state.prospects_rewards.totalPages,
            loading: false,
          },
        };
      } else {
        return {
          ...state,
          property_prospect: {
            data: { ...state.property_prospect.data, ...action.data.prospect },
          },
        };
      }

    case actionTypes.UPDATE_PROSPECT_REWARDS_FAILURE:
      return {
        ...state,
        prospects_rewards: {
          data: state.prospects_rewards.data,
          error: action.error,
          loading: false,
          totalPages: state.prospects_rewards.totalPages,
        },
      };
    case actionTypes.CREATE_PROSPECT_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        property_prospect: {
          data: {
            ...state.property_prospect.data,
            timeline: [...state.property_prospect.data.timeline, action.data],
          },
        },
      });
    case actionTypes.UPDATE_PROSPECT_COMMENT_SUCCESS:
      let index = state.property_prospect.data.timeline.findIndex(
        (timeline) =>
          typeof timeline.value === "object" &&
          timeline.value.id === action.data.value.id
      );
      return Object.assign({}, state, {
        property_prospect: {
          data: {
            ...state.property_prospect.data,
            timeline: [
              ...state.property_prospect.data.timeline.slice(0, index),
              action.data,
              ...state.property_prospect.data.timeline.slice(index + 1),
            ],
          },
        },
      });
    case actionTypes.DELETE_PROSPECT_COMMENT_SUCCESS:
      let deletedCommentIndex = state.property_prospect.data.timeline.findIndex(
        (timeline) =>
          typeof timeline.value === "object" &&
          timeline.value.id === action.data.id
      );
      return Object.assign({}, state, {
        property_prospect: {
          data: {
            ...state.property_prospect.data,
            timeline: [
              ...state.property_prospect.data.timeline.slice(
                0,
                deletedCommentIndex
              ),
              ...state.property_prospect.data.timeline.slice(
                deletedCommentIndex + 1
              ),
            ],
          },
        },
      });
    case actionTypes.CREATE_PROSPECT_COMMENT_FAILURE:
    case actionTypes.UPDATE_PROSPECT_COMMENT_FAILURE:
    case actionTypes.DELETE_PROSPECT_COMMENT_FAILURE:
      return state;
    default:
      return state;
  }
};
