import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

export const CookieKeys = {
  Auth: 'authToken',
  Domains: process.env.REACT_APP_ALLOWED_COOKIE_DOMAINS || '.rentgrata.com',
};

export const TokenMaxAge = {
  EIGHT_HOURS: 8 * 60 * 60 * 1000,
  ZERO: 0,
};

export const AuthCookie = {
  get() {
    return Cookies.get(CookieKeys.Auth);
  },
  set(token) {
    Cookies.set(CookieKeys.Auth, token, {
      secure: ['production', 'staging'].includes(process.env.NODE_ENV),
      domain: CookieKeys.Domains,
      sameSite: 'lax',
      expires: TokenMaxAge.EIGHT_HOURS,
    });
  },
  remove() {
    Cookies.remove(CookieKeys.Auth, { domain: CookieKeys.Domains });
  },
  decoded() {
    try {
      return jwtDecode(AuthCookie.get());
    } catch {
      return null;
    }
  },
  isExpired() {
    const payload = AuthCookie.decoded();

    return payload?.exp === undefined || payload.exp < Math.floor(Date.now() / 1000);
  },
};
