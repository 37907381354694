import { keyMirror } from '../helpers/customMethods';

const analyticsEventCategory = Object.freeze(keyMirror({
  pages: null,
  search: null,
  loaded: null,
  authentication: null,
  drag_and_drop: null,
  user_management: null,
  property_management: null,
  dashboard: null,
  reports: null,
  neighbor_nurture: null,
  mark_rewarded_checkout: null,
}));

const analyticsEventActions = Object.freeze(keyMirror({
  nav_prospect_search_focused: null,
  nav_prospect_search_by_email: null,
  nav_prospect_search_by_username: null,
  unlocks_guest_card_by_email_search: null,
  search_for_property_resident: null,
  search_for_property_prospect_by_username: null,
  search_for_property_prospect_by_resident_name_or_unit_number: null,
  sign_in_user: null,
  sign_in_user_failed: null,
  sign_out_user: null,
  creates_password: null,
  creates_password_failed: null,
  updates_password: null,
  updates_password_failed: null,
  begin_drag: null,
  end_drag: null,
  invite_new_user: null,
  invite_new_user_failed: null,
  upload_avatar: null,
  upload_avatar_failed: null,
  update_referral_reward_amount: null,
  update_referral_reward_amount_failed: null,
  updates_proximity_words_user: null,
  updates_proximity_words_entity: null,
  updates_resident_responses_entity: null,
  clicks_on_top_resident: null,
  generate: null,
  export: null,
  clicks_short_link: null,
  activate_property: null,
  deactivate_property: null,
  global_activate_properties: null,
  global_deactivate_properties: null,
  activate_lead_source: null,
  deactivate_lead_source: null,
  global_activate_lead_source: null,
  global_deactivate_lead_source: null,
  confirm_reward_paid: null,
}));

const analyticModals = Object.freeze(keyMirror({
  new_user: null,
  applied_status: null,
  application_submitted_status: null,
  moved_in_status: null,
  denied_lost_status: null,
  change_referral_reward: null,
  active_status: null,
  signed_lease_details: null,
}));

const analyticsDimensions = Object.freeze(keyMirror({
  dimension1: null,
}));

const analyticsDimensionsGA4 = Object.freeze(keyMirror({
  listings_selected: null,
  prospect_id: null,
  listing_id: null,
  property_manager_id: null,
  lead_source_id: null,
  manager_user_id: null,
}));

const analyticsMetrics = Object.freeze(keyMirror({
  metric1: null,
}));

const analyticsMetricsGA4 = Object.freeze(keyMirror({
  duration_days: null,
}));

export {
  analyticsEventCategory,
  analyticsEventActions,
  analyticModals,
  analyticsDimensions,
  analyticsMetrics,
  analyticsDimensionsGA4,
  analyticsMetricsGA4,
};
