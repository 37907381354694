import { compose, createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers/rootReducer';

const name = 'Debugging';
const composeEnhancers =
  process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name, id: name })
    : compose;

const persistConfig = {
  storage,
  key: 'root',
  whitelist: ['rewards'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (initialState = {}) => createStore(
  persistedReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(thunk),
  ),
);

export const store = configureStore();
export const persistor = persistStore(store);
