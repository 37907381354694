/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import { pageLoading, objectWithPagination } from './utils';
import propertyRequest from '../requests/propertyRequests';
import { asyncHandler, safeGetProperty } from '../helpers/customMethods';
import displayNotification from '../components/shared/DisplayNotification.js';
import * as propertyActions from './actions/property';
import { updateResidentsCount, resetProperties } from './actions/property';

export { updateResidentsCount, resetProperties };

export const fetchPropertyManager = () => (dispatch) => {
  propertyRequest
    .fetchPropertyManager()
    .then((response) => {
      dispatch(propertyActions.fetchPropertyManagerSuccess(response.data));
    })
    .catch((error) => {
      dispatch(propertyActions.fetchPropertyManagerFailure(error.response));
    });
};

export const fetchPropertyManagerUsers = (filters) => (dispatch) => {
  dispatch(pageLoading('propertyUsers', true));
  propertyRequest
    .fetchPropertyManagerUsers(filters)
    .then((response) => {
      dispatch(
        propertyActions.fetchPropertyManagerUsersSuccess(objectWithPagination(response, filters)),
      );
    })
    .catch((error) => {
      dispatch(propertyActions.fetchPropertyManagerUsersFailure(error.response));
    });
};

export const fetchPropertyManagerUser = (userId) => (dispatch) => {
  propertyRequest
    .fetchPropertyManagerUser(userId)
    .then((response) => {
      dispatch(propertyActions.fetchPropertyManagerUserSuccess(response.data));
    })
    .catch((error) => {
      dispatch(propertyActions.fetchPropertyManagerUserFailure(error.response.data));
    });
};

export const updatePropertyManagerUser = (userID, values) => async (dispatch) => {
  const promise = propertyRequest.updatePropertyManagerUser(userID, values);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.updatePropertyManagerUserSuccess(response.data));
  }
  return dispatch(propertyActions.updatePropertyManagerUserFailure(error.response));
};

export const fetchProperties = (filters) => async (dispatch) => {
  dispatch(pageLoading('properties', true));

  const promise = propertyRequest.fetchProperties(filters);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(
      propertyActions.fetchPropertiesSuccess(
        objectWithPagination(response, filters),
      ),
    );
  }
  return dispatch(propertyActions.fetchPropertiesFailure(error.response));
};

export const fetchNeighborNurtureProperties = (filters) => async (dispatch) => {
  const promise = propertyRequest.fetchNeighborNurtureProperties(filters);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(
      propertyActions.fetchNeighborNurturePropertiesSuccess(
        objectWithPagination(response, filters),
      ),
    );
  }
  return dispatch(propertyActions.fetchNeighborNurturePropertiesFailure(error.response));
};

export const updateNeighborNurtureProperty = (listingId, params) => async (dispatch) => {
  dispatch(pageLoading('properties', true));

  const promise = propertyRequest.updateNeighborNurtureProperty(listingId, params);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.updateNeighborNurturePropertySuccess(response.data));
  }
  return dispatch(propertyActions.updateNeighborNurturePropertyFailure(error.response));
};

export const updateGlobalOverrideNeighborNurture = (params) => async (dispatch) => {
  const promise = propertyRequest.updateGlobalOverrideNeighborNurture(params);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.updateGlobalOverrideNeighborNurtureSuccess(response.data));
  }
  return dispatch(propertyActions.updateGlobalOverrideNeighborNurtureFailure(error.response));
};

export const fetchPropertyManagers = (filters) => async (dispatch) => {
  dispatch(pageLoading('propertyManagers', true));

  const promise = propertyRequest.fetchPropertyManagers(filters);
  const { ok, response, error } = await asyncHandler(promise);
  if (ok) {
    return dispatch(
      propertyActions.fetchPropertyManagersSuccess(
        objectWithPagination(response, filters),
      ),
    );
  }
  return dispatch(propertyActions.fetchPropertyManagersFailure(error.response));
};

export const fetchPropertyFloorplans = (listingId, filters) => async (dispatch) => {
  dispatch(pageLoading('floorplans', true));

  const promise = propertyRequest.fetchPropertyFloorplans(listingId, filters);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(
      propertyActions.fetchPropertyFloorplansSuccess(
        objectWithPagination(response, filters),
      ),
    );
  }
  return dispatch(propertyActions.fetchPropertyFloorplansFailure(error.response));
};

export const fetchProperty = (listingId, filters) => (dispatch) => propertyRequest
  .fetchProperty(listingId, filters)
  .then((response) => {
    dispatch(propertyActions.fetchPropertySuccess(response.data, filters));
    return response.data;
  })
  .catch((error) => {
    dispatch(propertyActions.fetchPropertyFailure(error.response));
  });

export const updateProperty = (listingId, payload, successMsg) => async (dispatch) => {
  const promise = propertyRequest.updateProperty(listingId, payload);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    const success_data = safeGetProperty('data', response.data);
    const success_msg = successMsg || safeGetProperty('message', response.data);

    dispatch(propertyActions.updatePropertySuccess(success_data));
    if (payload.prospect_reward || payload.reward_amount) {
      displayNotification('Reward amount updated successfully');
    } else {
      displayNotification(success_msg);
    }
    return Promise.resolve();
  }
  dispatch(propertyActions.updatePropertyFailure(error.response));
  return Promise.reject();
};

export const updateKnock = (listingId, payload, successMsg) => async (dispatch) => {
  const promise = propertyRequest.updateKnock(listingId, payload);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    const success_data = safeGetProperty('data', response.data);
    const success_msg = successMsg || safeGetProperty('message', response.data);

    dispatch(propertyActions.updatePropertySuccess(success_data));
    displayNotification(success_msg);
    return Promise.resolve();
  }
  dispatch(propertyActions.updatePropertyFailure(error.response));
  return Promise.reject();
};

export const initiateAutoInviter = (listingId) => async (dispatch) => {
  propertyRequest.initiateAutoInviter(listingId).then((response) => {
    const success_data = safeGetProperty('data', response.data);
    const success_msg = safeGetProperty('message', response.data);
    dispatch(propertyActions.updatePropertySuccess(success_data));
    displayNotification(success_msg);
  }).catch((error) => {
    displayNotification(error.response.data.message, 'error');
  });
};

export const validateListingThirdPartyIntegration = (listingId, payload) => async (dispatch) => propertyRequest.validateListingThirdPartyIntegration(listingId, payload).then((response) => {
  const success_data = safeGetProperty('data', response.data);
  const success_msg = safeGetProperty('message', response.data);
  dispatch(propertyActions.updatePropertySuccess(success_data));
  return success_msg;
}).catch((error) => {
  throw safeGetProperty('response.data.error', error) || 'Error validating integration';
});

export const deleteListingThirdPartyIntegration = (listingId, integrationType) => async (dispatch) => propertyRequest.deleteListingThirdPartyIntegration(listingId, integrationType).then((response) => {
  const success_data = safeGetProperty('data', response.data);
  const success_msg = safeGetProperty('message', response.data);
  dispatch(propertyActions.updatePropertySuccess(success_data));
  return success_msg;
}).catch((error) => {
  throw safeGetProperty('response.data.error', error) || 'Error deleting integration';
});

export const validateManagerThirdPartyIntegration = (propertyManagerID, payload) => async (dispatch) => propertyRequest.validateManagerThirdPartyIntegration(propertyManagerID, payload).then((response) => {
  const success_data = safeGetProperty('data', response.data);
  const success_msg = safeGetProperty('message', response.data);
  dispatch(propertyActions.fetchPropertyManagerSuccess(success_data));
  return success_msg;
}).catch((error) => {
  throw safeGetProperty('response.data.error', error) || 'Error validating integration';
});

export const deleteManagerThirdPartyIntegration = (propertyManagerID, integrationType) => async (dispatch) => propertyRequest.deleteManagerThirdPartyIntegration(propertyManagerID, integrationType).then((response) => {
  const success_data = safeGetProperty('data', response.data);
  const success_msg = safeGetProperty('message', response.data);
  dispatch(propertyActions.fetchPropertyManagerSuccess(success_data));
  return success_msg;
}).catch((error) => {
  throw safeGetProperty('response.data.error', error) || 'Error deleting integration';
});

export const fetchPropertyUsers = (listingId, filters) => (dispatch) => {
  dispatch(pageLoading('propertyUsers', true));
  propertyRequest
    .fetchPropertyUsers(listingId, filters)
    .then((response) => {
      dispatch(propertyActions.fetchPropertyUsersSuccess((objectWithPagination(response, filters))));
    })
    .catch((error) => {
      dispatch(propertyActions.fetchPropertyUsersFailure(error.response.data));
    });
};

export const fetchPropertyNotifications = (listingId) => async (dispatch) => {
  const promise = propertyRequest.fetchPropertyNotifications(listingId);

  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    const { data } = response;
    return dispatch(propertyActions.fetchPropertyNotificationsSuccess({
      open_leads: data.open_leads || 0,
      unrewarded_residents: data.unrewarded_residents || 0,
    }));
  }
  return dispatch(propertyActions.fetchPropertyNotificationsFailure(error.response));
};

export const fetchEntitySentiment = (listing_id, is_prospect) => async (dispatch) => {
  const promise = propertyRequest.fetchEntitySentiment(listing_id, is_prospect);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return is_prospect === true ? dispatch(propertyActions.fetchProspectSentimentSuccess(response.data)) : dispatch(propertyActions.fetchResidentSentimentSuccess(response.data));
  }
  return is_prospect === true ? dispatch(propertyActions.fetchProspectSentimentFailure(error.response)) : dispatch(propertyActions.fetchResidentSentimentFailure(error.response));
};

export const fetchProximityWords = (listing_id, filters) => async (dispatch) => {
  const promise = propertyRequest.fetchProximityWords(listing_id, filters);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.fetchProximityWordsSuccess(response.data));
  }
  return dispatch(propertyActions.fetchProximityWordsFailure(error.response));
};

export const fetchResidentResponses = (listing_id, filters) => async (dispatch) => {
  const promise = propertyRequest.fetchResidentResponses(listing_id, filters);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.fetchResidentResponsesSuccess(response.data));
  }
  return dispatch(propertyActions.fetchResidentResponsesFailure(error.response));
};

export const fetchTopResidents = (listing_id) => async (dispatch) => {
  const promise = propertyRequest.fetchTopResidents(listing_id);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.fetchTopResidentsSuccess(response.data));
  }
  return dispatch(propertyActions.fetchTopResidentsFailure(error.response));
};

export const fetchMovedInInterests = (listing_id) => async (dispatch) => {
  const promise = propertyRequest.fetchMovedInInterests(listing_id);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.fetchMovedInInterestsSuccess(response.data));
  }
  return dispatch(propertyActions.fetchMovedInInterestsFailure(error.response));
};

export const fetchLostConcerns = (listing_id) => async (dispatch) => {
  const promise = propertyRequest.fetchLostConcerns(listing_id);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.fetchLostConcernsSuccess(response.data));
  }
  return dispatch(propertyActions.fetchLostConcernsFailure(error.response));
};

export const fetchRewardSnapshot = (listing_id) => async (dispatch) => {
  const promise = propertyRequest.fetchRewardSnapshot(listing_id);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.fetchRewardSnapshotSuccess(response.data));
  }
  return dispatch(propertyActions.fetchRewardSnapshotFailure(error.response));
};

export const inviteResidents = (listing_id, residentList) => async () => {
  const formData = new FormData();
  if (residentList === 'realpage') {
    formData.append('realpage_onesite_inviter', true);
  } else if (residentList === 'yardi') {
    formData.append('yardi_inviter', true);
  } else if (residentList === 'entrata') {
    formData.append('entrata_inviter', true);
  } else if (residentList === 'resman') {
    formData.append('resman_inviter', true);
  } else {
    formData.append('resident_list_file', residentList);
  }
  const promise = propertyRequest.inviteResidents(listing_id, formData);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return response;
  } if (error) {
    return error.response;
  }

  return false;
};

export const updatePropertyBillingContact = (listingId, params) => async (dispatch) => {
  const promise = propertyRequest.updatePropertyBillingContact(listingId, params);
  const { ok, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.updatePropertyBillingContactSuccess(params));
  }
  dispatch(propertyActions.updatePropertyBillingContactFailure(error.response));
  throw error.response.data ? error.response.data : error.response;
};

export const sendWidgetInstallationInstructions = (listingId, params) => async (dispatch) => {
  const promise = propertyRequest.sendWidgetInstallationInstructions(listingId, params);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.sendWidgetInstallationInstructionsSuccess(response.data));
  }
  return dispatch(propertyActions.sendWidgetInstallationInstructionsFailure(error.response));
};

export const updateAnnounceWidget = (listingId, params) => async (dispatch) => {
  const promise = propertyRequest.updateAnnounceWidget(listingId, params);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.updateAnnounceWidgetSuccess(response.data));
  }
  return dispatch(propertyActions.updateAnnounceWidgetFailure(error.response));
};

export const updatePrintAndMakeCopies = (listingId, params) => async (dispatch) => {
  const promise = propertyRequest.updatePrintAndMakeCopies(listingId, params);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.updatePrintAndMakeCopiesSuccess(response.data));
  }
  return dispatch(propertyActions.updatePrintAndMakeCopiesFailure(error.response));
};

export const activateSubscription = (listingId) => async (dispatch) => {
  const promise = propertyRequest.activateSubscription(listingId);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.activateSubscriptionSuccess(response.data));
  }

  dispatch(propertyActions.activateSubscriptionFailure(error.response));
  throw error.response.data ? error.response.data : error.response;
};

export const fetchPaymentHistory = (listingId, year) => async (dispatch) => {
  const promise = propertyRequest.fetchPaymentHistory(listingId, year);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.fetchPaymentHistorySuccess(response.data));
  }

  dispatch(propertyActions.fetchPaymentHistoryFailure(error.response));
  throw error.response.data ? error.response.data : error.response;
};

export const exportPaymentInvoice = (params) => async (dispatch) => {
  const promise = propertyRequest.exportPaymentInvoice(params);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.exportPaymentInvoiceSuccess(response.data));
  }

  dispatch(propertyActions.exportPaymentInvoiceFailure(error.response));
  throw error.response.data ? error.response.data : error.response;
};

export const searchManagerUsers = (params) => async (dispatch) => {
  const promise = propertyRequest.searchManagerUsers(params);
  const { ok, error, response } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.searchManagerUsersSuccess(response.data));
  }

  dispatch(propertyActions.searchManagerUsersFailure(error.response));
  throw error.response.data ? error.response.data : error.response;
};

export const resumeService = (listingId) => async (dispatch) => {
  const promise = propertyRequest.resumeService(listingId);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.resumeServiceSuccess(response.data));
  }
  dispatch(propertyActions.resumeServiceFailure(error.response));
  throw error.response.data ? error.response.data : error.response;
};

export const retryPayment = (listingId) => async (dispatch) => {
  const promise = propertyRequest.retryPayment(listingId);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.retryPaymentSuccess(response.data));
  }
  dispatch(propertyActions.retryPaymentFailure(error.response));
  throw error.response.data ? error.response.data : error.response;
};
export const uploadMessengerConfig = async (listingId, payload, dispatch) => {
  const promise = propertyRequest.postMessengerConfig(listingId, payload);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.postMessengerConfigSuccess(response.data));
  }

  return dispatch(propertyActions.postMessengerConfigError(error.response));
};

export const addStripeCardAccount = (params) => async (dispatch) => {
  const promise = propertyRequest.addStripeCardAccount(params);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    dispatch(propertyActions.addStripeCardAccountSuccess(response.data));
    return response.data.billing_account.credit_card;
  }

  throw error.response.data ? error.response.data : error.response;
};

export const addStripeBankAccount = (params) => async (dispatch) => {
  const promise = propertyRequest.addStripeBankAccount(params);
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    dispatch(propertyActions.addStripeBankAccountSuccess(response.data));
    return response.data.billing_account.bank_account;
  }

  throw error.response.data ? error.response.data : error.response;
};

export const addBillingAddress = (listing_id, params) => async (dispatch) => {
  const promise = propertyRequest.addBillingAddress({ listing_id, ...params });
  const { ok, response, error } = await asyncHandler(promise);

  if (ok) {
    dispatch(propertyActions.addBillingAddressSuccess(response.data));
    return response.data.billing_account.billing_address;
  }

  throw error.response.data ? error.response.data : error.response;
};

export const removeStripeCardAccount = (params) => async (dispatch) => {
  const promise = propertyRequest.removeStripeCardAccount(params);
  const { ok, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.removeStripeCardAccountSuccess(params));
  }

  dispatch(propertyActions.removeStripeCardAccountFailure(error.response));
  throw error.response.data ? error.response.data : error.response;
};

export const fetchPropertyBillingAccounts = (params) => async (dispatch) => {
  const promise = propertyRequest.fetchPropertyBillingAccounts(params);
  const { ok, response } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.fetchPropertyBillingAccountsSuccess(response.data));
  }
  return null;
};

export const verifyBankAccount = (params) => async (dispatch) => {
  const promise = propertyRequest.verifyBankAccount(params);
  const { ok, error } = await asyncHandler(promise);

  if (ok) {
    return dispatch(propertyActions.verifyStripeBankAccountSuccess(params));
  }

  dispatch(propertyActions.verifyStripeBankAccountFailure(error.response));
  throw error.response.data ? error.response.data : error.response;
};
