import moment from 'moment-timezone';
import validate from 'validate.js';
import capitalize from 'lodash/capitalize';
import { rewardsv2Enabled, neighborNurtureEnabled } from './features';

export const isObjEmpty = (obj) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

/**
 * @desc Returns the moment object of a date in the local time zone of the browser
 * @param {string} date
 * @returns {object|moment.Moment}
 */
export const toLocalDate = (date) => {
  if (date) {
    return moment(date).local();
  }
  return null;
};

export const formatDatel = (date) => {
  if (date) {
    return moment(date).format('MM/DD/YYYY');
  }
  return '';
};

export const formatDateL = (date) => {
  if (date) {
    return moment(date).format('l');
  }
  return '';
};

export const formatDateWithTimezone = (date) => {
  if (date) {
    // Get the browser's timezone
    const timezone = moment.tz.guess();
    return moment(date).tz(timezone).format('MM/DD/YY, h:mma z');
  }
  return '';
};

export const formatDateLL = (date) => {
  if (date) {
    return moment(date).format('ll');
  }
  return '';
};

export const formatTime = (date) => moment(date).format('hh:mm a');

export const getMonthDay = (date) => {
  if (!date) return '';

  return moment(date).format('MMM Do');
};

export const formatTimelineTime = (date) => {
  if (!date) return '';
  return moment(new Date(date)).local().format('MMMM D, YYYY, h:mma');
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const getName = (data) => {
  if (data) {
    const name = data.split(',')[0];
    return name || '';
  }
  return '';
};

export const getCount = (data) => {
  if (data) {
    const count = data.split(',')[1];
    // eslint-disable-next-line radix
    return parseInt(count) ? count : '';
  }
  return '';
};

export const createPropertiesString = (properties) => {
  if (properties.length > 1 && Array.isArray(properties)) {
    let propString = properties.join(', ');
    if (propString.length > 100) {
      propString = `${propString.substring(0, 99)}...`;
    }
    return propString;
  }
  return properties;
};

export async function asyncHandler(promise) {
  return promise
    .then((response) => ({
      ok: true,
      response,
      error: {},
    }))
    .catch((error) => ({
      ok: false,
      response: {},
      error,
    }));
}

export const setGraphWidth = (screenWidth) => {
  const screenToGraphRatio = screenWidth < 720 ? 1.15 : 5.2;
  return screenWidth / screenToGraphRatio;
};

export const userImage = (user = {}) => {
  if (user.image_url && user.image_url.url) {
    return user.image_url.url;
  }
  return user.image_url || ' ';
};

export const safeGetProperty = (str, hash) => {
  const keys = str.split(/[[|\]|.]/).filter((key) => key);

  return keys.reduce((obj, key) => (obj && obj[key] ? obj[key] : undefined), hash);
};

export const serializePagination = (action = {}) => ({
  totalPages: parseInt(action.totalPages, 10),
  currentPage: parseInt(action.currentPage, 10),
});

export const serializeActivityFeedPagination = (action = {}) => ({
  hideOnSinglePage: true,
  totalPages: parseInt(action.totalPages, 15),
  currentPage: parseInt(action.currentPage, 15),
});

export const getPaginationData = (payload, currentPage, opts = {}) => {
  const paginationData = {
    hideOnSinglePage: true,
    total: parseInt(payload.totalPages, 10),
    current: parseInt(currentPage || payload.currentPage, 10),
  };

  return Object.assign(paginationData, opts);
};

const toCurrency = (amount) => `$${amount.toLocaleString('en-US')}`;

// eslint-disable-next-line no-extend-native,func-names
Number.prototype.toCurrency = function () {
  return toCurrency(this);
};

export const isEmail = (val) => !validate.single(val, { email: true, presence: true });

export const stringifyStatus = (status) => {
  if (status === 'moved_in') {
    return 'Moved in';
  } if (status === 'denied_or_lost') {
    return 'Denied/Lost';
  }
  return capitalize(status).toString();
};

export const keyMirror = (hash) => {
  const obj = {};
  let key = null;

  // eslint-disable-next-line no-restricted-syntax
  for (key in hash) {
    // eslint-disable-next-line no-prototype-builtins
    if (hash.hasOwnProperty(key)) {
      obj[key] = key;
    }
  }
  return obj;
};

export const retryFn = (fn, retriesLeft = 10, interval = 1000) => new Promise((resolve, reject) => {
  fn()
    .then(resolve)
    .catch((error) => {
      // eslint-disable-next-line consistent-return
      setTimeout(() => {
        if (retriesLeft === 1) {
          return reject(error);
        }
        retryFn(fn, retriesLeft - 1, interval).then(resolve, reject);
      });
    });
});

export const registerIntersectionObserver = (targetEl, callBack) => {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    const oberver = new IntersectionObserver(callBack);
    oberver.observe(targetEl);
  }
};

export const handleMobileCollapseMenuIntersection = ([entry]) => {
  const collapseMenu = document.querySelector('#sidebar__collapse');
  const header = document.querySelector('.header__right');
  if (collapseMenu) {
    if (
      (header && header.getBoundingClientRect().top > -10) ||
      (entry && entry.isIntersecting)
    ) {
      collapseMenu.classList.remove('__not-intersecting');
    } else {
      collapseMenu.classList.add('__not-intersecting');
    }
  }
};

export const transformName = (name) => {
  if (!name) return;
  const [firstName, lastName] = name.split(' ');
  // eslint-disable-next-line consistent-return
  return lastName ? `${firstName} ${lastName[0]}.` : firstName;
};

export function handleEnterKeyPress(fn) {
  return function (event) {
    if (event.keyCode === 13) {
      fn();
    }
  };
}

export const handleButtonKeyPress = (fn) => function (event) {
  if (event.keyCode === 13 || event.keyCode === 32) {
    fn(event);
  }
};

export const getRolesNotAboveMe = (rolesObject, role) => {
  const keyValueRolesArr = Object.entries(rolesObject);
  let roleIndex = 0;

  for (let index = 0; index < keyValueRolesArr.length; index += 1) {
    if (role === keyValueRolesArr[index][1]) {
      roleIndex = index;
      break;
    }
  }

  return keyValueRolesArr.splice(roleIndex);
};

export const genRewardText = (propertyInfo) => {
  if (
    propertyInfo.reward_amount === 0 &&
    propertyInfo.prospect_reward === 0 &&
    propertyInfo.resident_reward === 0
  ) return 'Reward Off';

  if (
    propertyInfo.reward_amount === null &&
    propertyInfo.prospect_reward === null &&
    propertyInfo.resident_reward === null
  ) return 'Not Set';

  const totalRewardAmount =
    propertyInfo.prospect_reward && propertyInfo.resident_reward
      ? Number(propertyInfo.prospect_reward + propertyInfo.resident_reward)
      : Number(propertyInfo.reward_amount);

  return `$${Number(totalRewardAmount)}`;
};

export function capitalizeFirstLetter(string) {
  return String(string[0]).toUpperCase() + string.substring(1);
}

export const formatStatus = (new_status) => {
  if (
    new_status === 'active' ||
    new_status === 'applied' ||
    new_status === 'lease_signed'
  ) {
    return 'active';
  }
  return new_status;
};

function getReportColumns(report) {
  return Object.keys(report).map((key) => ({
    title: capitalizeFirstLetter(key.split('_').join(' ')),
    dataIndex: key,
    key,
    render(value) {
      if (typeof value === 'number') {
        return Math.round(value);
      }
      return value;
    },
  }));
}

function handleDaysDiff(days) {
  if (days <= 30) {
    return `${days}+ days`;
  }

  if (days > 30 && days <= 365) {
    const months = Math.floor(days / 30);
    return months === 1 ? '1+ month' : `${Math.floor(days / 30)}+ months`;
  }

  const years = Math.floor(days / 365);
  return years === 1 ? '1+ year' : `${Math.floor(days / 365)}+ years`;
}

function pastDueDaysColumnRender(_, row) {
  const movedInDate = new Date(row.prospect_moved_in_date);
  const todaysDate = new Date();
  const daysDiff = (todaysDate.getTime() - movedInDate.getTime()) / (1000 * 3600 * 24);
  return handleDaysDiff(daysDiff);
}

function addNewColumn(columnKey, columnRender, precedingColumnKey, columns) {
  const index = columns.findIndex((colum) => colum.key === precedingColumnKey);
  columns.splice(index + 1, 0, {
    title: capitalizeFirstLetter(columnKey.split('_').join(' ')),
    dataIndex: columnKey,
    key: columnKey,
    render: columnRender,
  });
  return columns;
}

export function getReportData(
  {
    data: {
      report: { result, ...rest },
    },
  },
  reportId,
) {
  if (Array.isArray(result) && result[0]) {
    const shouldAddNewColumn = reportId === 'unpaid_reward_reports';
    return {
      columns: shouldAddNewColumn
        ? addNewColumn(
          'past_due_days',
          pastDueDaysColumnRender,
          'prospect_moved_in_date',
          getReportColumns(result[0]),
        )
        : getReportColumns(result[0]),
      data_source: result,
      ...rest,
    };
  }
  return { columns: [], data_source: [], ...rest };
}

export function reverseStr(str) {
  if (!str) return '';
  const strArr = str.split('');
  return strArr.reverse().join('');
}

export function reverseDateStrFormat(dateStr) {
  if (!dateStr) return '';
  const [year, month, day] = dateStr.split('-');
  return `${Number(day)}-${Number(month)}-${Number(year)}`;
}

export function compareListingName(listingA, listingB) {
  const nameA = listingA[1].toUpperCase();
  const nameB = listingB[1].toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export function onPage(path, uri) {
  return path === uri;
}

export function getDashboardLayoutClassName(path) {
  if (rewardsv2Enabled() && onPage(path, '/rewards')) {
    return 'off-white-bg';
  }

  return '';
}

export function getDashboardContentClassName(path) {
  if (rewardsv2Enabled() && onPage(path, '/rewards')) {
    return 'rewards rewardsv2';
  }

  if (neighborNurtureEnabled() && onPage(path, '/neighbor-nurture')) {
    return 'neighborNurture neighborNurtureV1';
  }

  return '';
}

export function getDashboardFooterClassName(path) {
  if (rewardsv2Enabled() && onPage(path, '/rewards')) {
    return 'off-white-bg';
  }

  return '';
}
