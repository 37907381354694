import React from 'react';
import { Spin } from 'antd';

const AppLoader = ({ loading, style }) => {
  return (
    <div className="loader__wrapper" style={{ display: loading ? 'flex' : 'none', ...style }}>
      <Spin spinning={loading} />
    </div>
  )
}

AppLoader.defaultProps = {
  loading: true,
  style: {}
};

export default AppLoader;
