import actionTypes from '../../constants/actionTypes';

export const fetchPropertyManagerSuccess = (data) => ({
  type: actionTypes.FETCH_PROPERTY_MANAGER_SUCCESS,
  data,
});

export const fetchPropertyManagerFailure = (error) => ({
  type: actionTypes.FETCH_PROPERTY_MANAGER_FAILURE,
  error,
});

export const fetchPropertyManagerUsersSuccess = (data) => ({
  type: actionTypes.FETCH_PROPERTY_MANAGER_USERS_SUCCESS,
  ...data,
});

export const fetchPropertyManagerUsersFailure = (error) => ({
  type: actionTypes.FETCH_PROPERTY_MANAGER_USERS_FAILURE,
  error,
});

export const fetchPropertyManagerUserSuccess = (data) => ({
  type: actionTypes.FETCH_PROPERTY_MANAGER_USER_SUCCESS,
  data,
});

export const updatePropertyManagerUserFailure = (error) => ({
  type: actionTypes.UPDATE_PROPERTY_MANAGER_USER_FAILURE,
  error,
});

export const updatePropertyManagerUserSuccess = (data) => ({
  type: actionTypes.UPDATE_PROPERTY_MANAGER_USER_SUCCESS,
  data,
});

export const fetchPropertyManagerUserFailure = (error) => ({
  type: actionTypes.FETCH_PROPERTY_MANAGER_USER_FAILURE,
  error,
});

export const resumeServiceSuccess = (data) => ({
  type: actionTypes.RESUME_SERVICE_SUCCESS,
  data,
});

export const resumeServiceFailure = (error) => ({
  type: actionTypes.RESUME_SERVICE_FAILURE,
  error,
});

export const retryPaymentSuccess = (data) => ({
  type: actionTypes.RETRY_PAYMENT_SUCCESS,
  data,
});

export const retryPaymentFailure = (error) => ({
  type: actionTypes.RETRY_PAYMENT_FAILURE,
  error,
});

export const fetchPropertySuccess = (data) => ({
  type: actionTypes.FETCH_PROPERTY_SUCCESS,
  data,
});

export const fetchPropertyFailure = (error) => ({
  type: actionTypes.FETCH_PROPERTY_FAILURE,
  error,
});

export const updatePropertySuccess = (data) => ({
  type: actionTypes.UPDATE_PROPERTY_SUCCESS,
  data,
});

export const updatePropertyFailure = (error) => ({
  type: actionTypes.UPDATE_PROPERTY_FAILURE,
  error,
});

export const fetchPropertyFloorplansSuccess = (data) => ({
  type: actionTypes.FETCH_FLOORPLANS_SUCCESS,
  ...data,
});

export const fetchPropertyFloorplansFailure = (error) => ({
  type: actionTypes.FETCH_FLOORPLANS_FAILURE,
  error,
});

export const resetProperties = () => ({
  type: actionTypes.RESET_PROPERTIES,
  properties: [],
});

export const fetchPropertiesSuccess = (data) => ({
  type: actionTypes.FETCH_PROPERTIES_SUCCESS,
  ...data,
});

export const fetchPropertiesFailure = (error) => ({
  type: actionTypes.FETCH_PROPERTIES_FAILURE,
  error,
});

export const fetchNeighborNurturePropertiesSuccess = (data) => ({
  type: actionTypes.FETCH_NEIGHBOR_NURTURE_PROPERTIES_SUCCESS,
  ...data,
});

export const fetchNeighborNurturePropertiesFailure = (error) => ({
  type: actionTypes.FETCH_NEIGHBOR_NURTURE_PROPERTIES_FAILURE,
  error,
});

export const updateNeighborNurturePropertySuccess = (data) => ({
  type: actionTypes.UPDATE_NEIGHBOR_NURTURE_PROPERTY_SUCCESS,
  data,
});

export const updateNeighborNurturePropertyFailure = (error) => ({
  type: actionTypes.UPDATE_NEIGHBOR_NURTURE_PROPERTY_FAILURE,
  error,
});

export const updateGlobalOverrideNeighborNurtureSuccess = (data) => ({
  type: actionTypes.UPDATE_GLOBAL_OVERRIDE_NEIGHBOR_NURTURE_SUCCESS,
  data,
});

export const updateGlobalOverrideNeighborNurtureFailure = (error) => ({
  type: actionTypes.UPDATE_GLOBAL_OVERRIDE_NEIGHBOR_NURTURE_FAILURE,
  error,
});

export const fetchPropertyManagersSuccess = (data) => ({
  type: actionTypes.FETCH_PROPERTIES_MANAGERS_SUCCESS,
  ...data,
});

export const fetchPropertyManagersFailure = (error) => ({
  type: actionTypes.FETCH_PROPERTIES_MANAGERS_FAILURE,
  error,
});

export const fetchPropertyUsersSuccess = (data) => ({
  type: actionTypes.FETCH_PROPERTY_USERS_SUCCESS,
  ...data,
});

export const fetchPropertyUsersFailure = (error) => ({
  type: actionTypes.FETCH_PROPERTY_USERS_FAILURE,
  error,
});

export const fetchPropertyNotificationsSuccess = (data) => ({
  type: actionTypes.FETCH_PROPERTY_NOTIFICAITONS_SUCCESS,
  data,
});

export const fetchPropertyNotificationsFailure = (error) => ({
  type: actionTypes.FETCH_PROPERTY_NOTIFICAITONS_FAILURE,
  error,
});

export const updateResidentsCount = (operand) => ({
  type: actionTypes.UPDATE_REWARDS_NOTIFICATION,
  operand,
});

export const fetchProspectSentimentSuccess = (data) => ({
  type: actionTypes.FETCH_PROSPECT_SENTIMENT_SUCCESS,
  data,
});

export const fetchProspectSentimentFailure = () => ({
  type: actionTypes.FETCH_PROSPECT_SENTIMENT_FAILURE,
});

export const fetchResidentSentimentSuccess = (data) => ({
  type: actionTypes.FETCH_RESIDENT_SENTIMENT_SUCCESS,
  data,
});

export const fetchResidentSentimentFailure = () => ({
  type: actionTypes.FETCH_RESIDENT_SENTIMENT_FAILURE,
});

export const fetchProximityWordsSuccess = (data) => ({
  type: actionTypes.FETCH_PROXIMITY_WORDS_SUCCESS,
  data,
});

export const fetchProximityWordsFailure = (error) => ({
  type: actionTypes.FETCH_PROXIMITY_WORDS_FAILURE,
  error,
});

export const fetchResidentResponsesSuccess = (data) => ({
  type: actionTypes.FETCH_RESIDENT_RESPONSES_SUCCESS,
  data,
});

export const fetchResidentResponsesFailure = (error) => ({
  type: actionTypes.FETCH_RESIDENT_RESPONSES_FAILURE,
  error,
});

export const fetchTopResidentsSuccess = (data) => ({
  type: actionTypes.FETCH_TOP_RESIDENTS_SUCCESS,
  data,
});

export const fetchTopResidentsFailure = (error) => ({
  type: actionTypes.FETCH_TOP_RESIDENTS_FAILURE,
  error,
});

export const fetchMovedInInterestsSuccess = (data) => ({
  type: actionTypes.FETCH_MOVED_IN_INTERESTS_SUCCESS,
  data,
});

export const fetchMovedInInterestsFailure = (error) => ({
  type: actionTypes.FETCH_MOVED_IN_INTERESTS_FAILURE,
  error,
});

export const fetchLostConcernsSuccess = (data) => ({
  type: actionTypes.FETCH_LOST_CONCERNS_SUCCESS,
  data,
});

export const fetchLostConcernsFailure = (error) => ({
  type: actionTypes.FETCH_LOST_CONCERNS_FAILURE,
  error,
});

export const fetchRewardSnapshotSuccess = (data) => ({
  type: actionTypes.FETCH_REWARD_SNAPSHOT_SUCCESS,
  data,
});

export const fetchRewardSnapshotFailure = (error) => ({
  type: actionTypes.FETCH_REWARD_SNAPSHOT_FAILURE,
  error,
});

export const updatePropertyBillingContactSuccess = (data) => ({
  type: actionTypes.UPDATE_PROPERTY_BILLING_CONTACT_SUCCESS,
  data,
});

export const updatePropertyBillingContactFailure = (error) => ({
  type: actionTypes.UPDATE_PROPERTY_BILLING_CONTACT_FAILURE,
  error,
});

export const sendWidgetInstallationInstructionsSuccess = (data) => ({
  type: actionTypes.SEND_WIDGET_INSTALLATION_INSTRUCTION_SUCCESS,
  data,
});

export const sendWidgetInstallationInstructionsFailure = (error) => ({
  type: actionTypes.SEND_WIDGET_INSTALLATION_INSTRUCTION_FAILURE,
  error,
});

export const updateAnnounceWidgetSuccess = (data) => ({
  type: actionTypes.UPDATE_ANNOUNCE_WIDGET_SUCCESS,
  data,
});

export const updateAnnounceWidgetFailure = (error) => ({
  type: actionTypes.UPDATE_ANNOUNCE_WIDGET_FAILURE,
  error,
});

export const updatePrintAndMakeCopiesSuccess = (data) => ({
  type: actionTypes.UPDATE_PRINT_AND_MAKE_COPIES_SUCCESS,
  data,
});

export const updatePrintAndMakeCopiesFailure = (error) => ({
  type: actionTypes.UPDATE_PRINT_AND_MAKE_COPIES_FAILURE,
  error,
});

export const activateSubscriptionSuccess = (data) => ({
  type: actionTypes.ACTIVATE_SUBSCRIPTION_SUCCESS,
  data,
});

export const activateSubscriptionFailure = (error) => ({
  type: actionTypes.ACTIVATE_SUBSCRIPTION_FAILURE,
  error,
});

export const fetchPaymentHistorySuccess = (data) => ({
  type: actionTypes.FETCH_PAYMENT_HISTORY_SUCCESS,
  data,
});

export const fetchPaymentHistoryFailure = (error) => ({
  type: actionTypes.FETCH_PAYMENT_HISTORY_FAILURE,
  error,
});

export const exportPaymentInvoiceSuccess = (data) => ({
  type: actionTypes.EXPORT_PAYMENT_INVOICE_SUCCESS,
  data,
});

export const exportPaymentInvoiceFailure = (error) => ({
  type: actionTypes.EXPORT_PAYMENT_INVOICE_FAILURE,
  error,
});

export const searchManagerUsersSuccess = (data) => ({
  type: actionTypes.SEARCH_MANAGER_USERS_SUCCESS,
  data,
});

export const searchManagerUsersFailure = (error) => ({
  type: actionTypes.SEARCH_MANAGER_USERS_FAILURE,
  error,
});

export const postMessengerConfigSuccess = (data) => ({
  type: actionTypes.POST_MESSENGER_CONFIG_SUCCESS,
  data,
});

export const postMessengerConfigError = (error) => ({
  type: actionTypes.POST_MESSENGER_CONFIG_FAILURE,
  error,
});

export const addStripeCardAccountSuccess = (data) => ({
  type: actionTypes.ADD_STRIPE_CARD_ACCOUNT_SUCCESS,
  data,
});

export const addStripeCardAccountFailure = (error) => ({
  type: actionTypes.ADD_STRIPE_CARD_ACCOUNT_FAILURE,
  error,
});

export const addBillingAddressSuccess = (data) => ({
  type: actionTypes.ADD_BILLING_ADDRESS_SUCCESS,
  data,
});

export const addBillingAddressFailure = (error) => ({
  type: actionTypes.ADD_BILLING_ADDRESS_FAILURE,
  error,
});

export const addStripeBankAccountSuccess = (data) => ({
  type: actionTypes.ADD_STRIPE_BANK_ACCOUNT_SUCCESS,
  data,
});

export const addStripeBankAccountFailure = (error) => ({
  type: actionTypes.ADD_STRIPE_BANK_ACCOUNT_FAILURE,
  error,
});

export const removeStripeCardAccountSuccess = (data) => ({
  type: actionTypes.REMOVE_STRIPE_ACCOUNT_SUCCESS,
  data,
});

export const removeStripeCardAccountFailure = (error) => ({
  type: actionTypes.REMOVE_STRIPE_ACCOUNT_FAILURE,
  error,
});

export const fetchPropertyBillingAccountsSuccess = (data) => ({
  type: actionTypes.FETCH_USER_BILLING_ACCOUNTS_SUCCESS,
  data,
});

export const fetchPropertyBillingAccountsFailure = (error) => ({
  type: actionTypes.FETCH_USER_BILLING_ACCOUNTS_FAILURE,
  error,
});

export const verifyStripeBankAccountSuccess = (data) => ({
  type: actionTypes.VERIFY_BANK_ACCOUNT_SUCCESS,
  data,
});

export const verifyStripeBankAccountFailure = (error) => ({
  type: actionTypes.VERIFY_BANK_ACCOUNT_FAILURE,
  error,
});
