import actionTypes from "../constants/actionTypes";
import { compareListingName } from "../helpers/customMethods";

const initialReportsState = {
  reports: {
    loading: false,
    data: [],
    error: null,
    totalPages: 0,
  },
  selectedReport: {
    data: {},
    loading: false,
    error: null,
  },
};

export const reports = (state = initialReportsState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REPORTS_LOADING:
      return Object.assign({}, state, {
        reports: {
          data: [],
          error: null,
          loading: true,
        },
      });
    case actionTypes.FETCH_REPORTS_SUCCESS:
      return Object.assign({}, state, {
        reports: {
          data: action.data
            .filter((report) => report.report_key)
            .map((report) => {
              return {
                ...report,
                report_listings: report.report_listings
                  .sort(compareListingName),
              };
            }),
          error: null,
          loading: false,
        },
      });
    case actionTypes.FETCH_REPORTS_FAILURE:
      return Object.assign({}, state, {
        reports: {
          data: [],
          error: action.error,
          loading: false,
        },
      });
    case actionTypes.FETCH_SELECTED_REPORT_LOADING:
      return Object.assign({}, state, {
        selectedReport: {
          data: {},
          error: null,
          loading: true,
        },
      });
    case actionTypes.FETCH_SELECTED_REPORT_SUCCESS:
      return Object.assign({}, state, {
        selectedReport: {
          data: action.data,
          error: null,
          loading: false,
        },
      });
    case actionTypes.FETCH_SELECTED_REPORT_FAILURE:
      return Object.assign({}, state, {
        selectedReport: {
          data: {},
          error: action.error,
          loading: false,
        },
      });
    case actionTypes.RESET_REPORT_DATA:
      return Object.assign({}, state, {
        selectedReport: {
          data: {},
          error: null,
          loading: false,
        },
      });
    case actionTypes.SIGN_OUT_SUCCESS:
      return initialReportsState;
    default:
      return state;
  }
};
