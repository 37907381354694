/* eslint-disable no-multi-spaces */
import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import isAuthenticated from './components/auth/isAuthenticated';
import preventAuthenticatedUser from './components/auth/preventAuthenticatedUser';
import RouteSuspense from './components/shared/RouteSuspense';
import { roles } from './helpers/accessControl/userRoles';
import { retryFn } from './helpers/customMethods';
import { adminBulkUpdatesEnabled, rewardsv2Enabled, rgPayEnabled } from './helpers/features';

const Users = lazy(() => retryFn(() => import('./components/users/Users')));
const Login = lazy(() => retryFn(() => import('./components/auth/Login')));
const PasswordReset = lazy(() => retryFn(() => import('./components/auth/PasswordReset')));
const User = lazy(() => retryFn(() => import('./components/users/User')));
const Reports = lazy(() => retryFn(() => import('./components/reports/Reports')));
const Settings = lazy(() => retryFn(() => import('./components/settings')));
const Properties = lazy(() => retryFn(() => import('./components/properties/Properties')));
const Property = lazy(() => retryFn(() => import('./components/properties/Property')));
const ProspectLandingPage = lazy(() => retryFn(() => import('./components/prospectLandingPage/ProspectLandingPage')));
const InviteResidents = lazy(() => retryFn(() => import('./components/properties/InviteResidents')));
const PropertyDashboard = lazy(() => retryFn(() => import('./components/dashboard/PropertyDashboard')));
const Prospect = lazy(() => retryFn(() => import('./components/prospect/Prospect')));
const Resident = lazy(() => retryFn(() => import('./components/residencies/Resident')));
const NotFound = lazy(() => retryFn(() => import('./components/notfound')));
const ChangePassword = lazy(() => retryFn(() => import('./components/auth/ChangePasswordPage')));
const PreviewEmail = lazy(() => retryFn(() => import('./components/common/PreviewEmail')));
const CreatePasswordError = lazy(() => retryFn(() => import('./components/notfound/createPasswordError')));
const MessengerQR = lazy(() => retryFn(() => import('./components/messengerQR')));
const TopicGuide = lazy(() => retryFn(() => import('./components/topicGuide')));
const NeighborNurture = lazy(() => retryFn(() => import('./components/neighborNurture/NeighborNurture')));
const NeighborNurtureLeadSources = lazy(() => retryFn(() => import('./components/neighborNurture/NeighborNurtureLeadSources')));
const PaymentSettings = lazy(() => retryFn(() => import('./components/payments/settings/Settings')));
const PaymentTransactions = lazy(() => retryFn(() => import('./components/payments/transactions/Transactions')));
const PaymentTransactionDetails = lazy(() => retryFn(() => import('./components/payments/transactions/TransactionDetails')));

function getAppRoutes(userId) {
  const isRewardsV2Enabled = rewardsv2Enabled(String(userId));
  const isRgPayEnabled = rgPayEnabled(String(userId));
  const isAdminBulkUpdateEnabled = adminBulkUpdatesEnabled();
  const default_route = isRewardsV2Enabled ? '/rewards' : '/properties';

  const Rewards = lazy(() => retryFn(() => {
    if (isRewardsV2Enabled) {
      return import('./components/rewards/RewardsWithRGPay');
    }
    return import('./components/rewards/Rewards');
  }));

  const routes = [
    {
      component: preventAuthenticatedUser(Login),
      exact: true,
      path: '/',
    },
    {
      component: ChangePassword,
      exact: true,
      path: '/password/:default_password',
    },
    {
      component: () => <Redirect to="/" />,
      exact: false,
      path: '/password',
    },
    {
      component: preventAuthenticatedUser(PasswordReset),
      exact: true,
      path: '/passwordreset',
    },
    {
      component: isAuthenticated(
        Users,
        [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
        ],
        default_route,
      ),
      exact: true,
      path: '/users',
    },
    {
      component: isAuthenticated(User, null, default_route),
      exact: true,
      path: '/users/:user_id',
    },
    {
      component: isAuthenticated(Settings),
      exact: false,
      path: '/settings',
    },
    {
      component: isAuthenticated(Properties),
      exact: true,
      path: '/properties',
    },
    {
      component: isAuthenticated(Resident),
      exact: true,
      path: '/properties/:listing_id/residents/:user_id',
    },
    {
      component: isAuthenticated(Prospect),
      exact: true,
      path: '/properties/:listing_id/prospects/:prospect_id',
    },
    {
      component: ProspectLandingPage,
      exact: true,
      path: '/prospect-landing-page',
    },
    {
      component: isAuthenticated(InviteResidents),
      exact: true,
      path: '/properties/:listing_id/invite-residents',
    },
    {
      component: isAuthenticated(Property),
      exact: false,
      path: '/properties/:listing_id',
    },
    {
      component: isAuthenticated(PropertyDashboard),
      exact: true,
      path: '/properties/:listing_id/dashboard/',
    },
    {
      component: isAuthenticated(
        Reports,
        [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ],
        default_route,
      ),
      exact: true,
      path: '/reports',
    },
    {
      component: isAuthenticated(
        Reports,
        [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ],
        default_route,
      ),
      exact: true,
      path: '/reports/:reportId',
    },
    {
      component: isAuthenticated(
        Rewards,
        [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ],
        default_route,
      ),
      exact: true,
      path: '/rewards',
    },
    {
      component: preventAuthenticatedUser(CreatePasswordError),
      exact: false,
      path: '/createpassworderror',
    },
    {
      component: isAuthenticated(PreviewEmail),
      exact: true,
      path: '/preview',
    },
    {
      component: MessengerQR,
      exact: true,
      path: '/messenger-qr',
    },
    {
      component: isAuthenticated(TopicGuide),
      exact: true,
      path: '/topic-guide',
    },
    {
      component: isAuthenticated(NeighborNurture),
      exact: true,
      path: '/neighbor-nurture',
    },
    {
      component: isAuthenticated(NeighborNurtureLeadSources),
      exact: true,
      path: '/neighbor-nurture/lead-sources',
    },
    {
      component: NotFound,
    },
  ];

  if (isRewardsV2Enabled) {
    const RewardDetails = lazy(() => retryFn(() => import('./components/rewards/RewardDetails')));
    const MarkRewarded = lazy(() => retryFn(() => import('./components/rewards/MarkRewarded')));
    const MarkRewardedSuccess = lazy(() => retryFn(() => import('./components/rewards/MarkRewarded/MarkRewardedSuccess')));
    const rewardsv2Routes = [
      {
        component: isAuthenticated(RewardDetails, [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ]),
        exact: true,
        path: '/rewards/details',
      },
      {
        component: isAuthenticated(MarkRewardedSuccess, [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ]),
        exact: true,
        path: '/rewards/mark-rewarded-success',
      },
      {
        component: isAuthenticated(MarkRewarded, [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ]),
        exact: true,
        path: '/rewards/mark-rewarded',
      },
    ];

    routes.splice(routes.length - 2, 0, ...rewardsv2Routes);
  }

  // configure RGPay routes
  if (isRgPayEnabled) {
    const RewardCheckout = lazy(() => retryFn(() => import('./components/rewards/RewardCheckout')));
    const RewardCheckoutSuccess = lazy(() => retryFn(() => import('./components/rewards/RewardCheckout/RewardCheckoutSuccess')));
    const PaymentMethods = lazy(() => retryFn(() => import('./components/payments/settings/PaymentMethods')));

    const rgPayRoutes = [
      {
        component: isAuthenticated(RewardCheckout, [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ]),
        exact: true,
        path: '/rewards/reward-checkout',
      },
      {
        component: isAuthenticated(RewardCheckoutSuccess, [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ]),
        exact: true,
        path: '/rewards/reward-checkout-success',
      },
      {
        component: isAuthenticated(PaymentTransactions, [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ]),
        exact: true,
        path: '/payments/transactions',
      },
      {
        component: isAuthenticated(PaymentTransactionDetails, [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ]),
        exact: true,
        path: '/payments/transactions/:transactionId',
      },
      {
        component: isAuthenticated(PaymentSettings, [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ]),
        exact: true,
        path: '/payments/settings',
      },
      {
        component: isAuthenticated(PaymentMethods, [
          roles.superAdmin,
          roles.accountAdmin,
          roles.companyAdmin,
          roles.integrationManager,
          roles.manager,
          roles.consultant,
        ]),
        exact: true,
        path: '/payments/settings/payment-methods',
      },
    ];
    routes.splice(routes.length - 2, 0, ...rgPayRoutes);
  }

  if (isAdminBulkUpdateEnabled) {
    const BulkUpdates = lazy(() => retryFn(() => import('./components/bulk-updates/BulkUpdates')));

    const bulkUpdateRoutes = [
      {
        component: isAuthenticated(BulkUpdates,
          [
            roles.superAdmin,
          ], default_route),
        exact: true,
        path: '/bulk-updates',
      },
    ];

    routes.splice(routes.length - 2, 0, ...bulkUpdateRoutes);
  }

  return routes;
}

export default function AppRoutes({ userId }) {
  return (
    <Switch>
      {getAppRoutes(userId).map((props) => (
        <Route
          key={props.path || 'not-found'}
          exact={props.exact}
          path={props.path}
          component={RouteSuspense(props.component)}
        />
      ))}
    </Switch>
  );
}
