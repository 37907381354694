import './polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor, store } from './store';
import * as serviceWorker from './serviceWorker';
import {
  fetchCurrentUser,
  handleToken,
  signInSuccess,
} from './actions/authActionCreators';
import httpClient, { xhr } from './helpers/httpClient';
import ErrorBoundary from './components/errorboundary';
import 'antd/dist/antd.css';
import 'typeface-source-sans-pro';
import './scss/application.scss';
import { AuthCookie } from './helpers/AuthCookie';

(async function initUserSetup() {
  store.dispatch(handleToken());
  const token = AuthCookie.get();

  if (token) {
    httpClient.setAuthorizationToken(token);
    xhr.setAuthorizationToken(token);
    await store.dispatch(fetchCurrentUser());
    store.dispatch(signInSuccess({ token }));
  }
}());

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <DragDropContextProvider backend={HTML5Backend}>
          <App />
        </DragDropContextProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
);

serviceWorker.unregister();
