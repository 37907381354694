/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import actionTypes from '../constants/actionTypes';
import { serializePagination } from '../helpers/customMethods';

const initialPropertyState = {
  propertyManager: {},
  propertyManagerUser: {
    data: '',
    error: null,
  },
  propertyManagers: {
    error: null,
    data: [],
    loading: false,
    totalPages: 0,
    currentPage: 1,
  },
  properties: {
    error: null,
    data: [],
    loading: false,
    totalPages: 0,
    currentPage: 1,
  },
  property: {
    loading: true,
  },
  proximityWords: {
    error: null,
    loading: true,
    data: [],
  },
  residentResponses: {
    error: null,
    loading: true,
    data: [],
  },
  topResidents: {
    error: null,
    loading: true,
    data: [],
  },
  movedInInterests: {
    error: null,
    loading: true,
    data: [],
  },
  lostConcerns: {
    error: null,
    loading: true,
    data: [],
  },
  propertyUsers: {
    data: [],
    loading: true,
    error: null,
    totalPages: 0,
    currentPage: 1,
    searchedManagers: [],
  },
  users: {
    data: [],
    loading: true,
    error: null,
    totalPages: 0,
    currentPage: 1,
  },
  floorplans: {
    data: [],
    loading: true,
    error: null,
    totalPages: 0,
    currentPage: 1,
  },
  prospectSentiment: {
    data: {
      positives: null,
      neutrals: null,
      negatives: null,
      experiences: null,
    },
  },
  residentSentiment: {
    data: {
      positives: null,
      neutrals: null,
      negatives: null,
      experiences: null,
    },
  },
  invitedUser: {
    message: '',
    status: null,
  },
  notifications: {
    open_leads: 0,
    unrewarded_residents: 0,
  },
  paymentHistory: {
    error: null,
    loading: false,
    data: [],
  },
  globalOverrides: {
    neighborNurture: true,
  },
  propertyBillingAccounts: {
    creditCards: [],
    bankAccounts: [],
    billingAddresses: [],
    status: {
      hasError: false,
    },
    message: '',
  },
};

export const property = (state = initialPropertyState, action) => {
  let bankAcct;
  let bankAccounts;

  switch (action.type) {
    case actionTypes.PAGE_LOADING:
      return { ...state, [action.key]: { ...state[action.key], loading: action.data } };
    case actionTypes.FETCH_PROPERTY_MANAGER_SUCCESS:
      return { ...state, propertyManager: action.data };
    case actionTypes.FETCH_USER_PROPERTY_MANAGER_FAILURE:
      return { ...state };
    case actionTypes.FETCH_PROPERTIES_SUCCESS:
      return { ...state,
        properties: {
          loading: false,
          data: action.data,
          ...serializePagination(action),
        } };
    case actionTypes.FETCH_PROPERTIES_FAILURE:
      return { ...state, properties: { error: action.error, loading: false, data: [] } };
    case actionTypes.FETCH_NEIGHBOR_NURTURE_PROPERTIES_SUCCESS:
      return { ...state,
        properties: {
          loading: false,
          data: action.data,
          ...serializePagination(action),
        },
        globalOverrides: {
          neighborNurture: action.data.every((v) => v.global_neighbor_nurture_enabled === true),
        } };
    case actionTypes.UPDATE_NEIGHBOR_NURTURE_PROPERTY_SUCCESS: {
      const { properties } = { ...state };
      const data = properties.data;
      const { success, listing } = action.data;

      if (success && listing) {
        data[data.findIndex((prop) => prop.id === listing?.id)] = listing;
      }

      return { ...state,
        properties: {
          data,
          loading: false,
        } };
    } case actionTypes.UPDATE_NEIGHBOR_NURTURE_PROPERTY_FAILURE:
      return { ...state,
        properties: {
          error: action.error,
          loading: false,
        } };
    case actionTypes.UPDATE_GLOBAL_OVERRIDE_NEIGHBOR_NURTURE_SUCCESS:
      return { ...state,
        globalOverrides: {
          neighborNurture: action.data.state,
        } };
    case actionTypes.UPDATE_GLOBAL_OVERRIDE_NEIGHBOR_NURTURE_FAILURE:
      return { ...state,
        globalOverrides: {
          neighborNurture: true,
        } };
    case actionTypes.FETCH_NEIGHBOR_NURTURE_PROPERTIES_FAILURE:
      return { ...state, properties: { error: action.error, loading: false, data: [] } };
    case actionTypes.FETCH_PROPERTIES_MANAGERS_SUCCESS:
      return { ...state,
        propertyManagers: {
          loading: false,
          data: action.data,
          ...serializePagination(action),
        } };
    case actionTypes.FETCH_PROPERTIES_MANAGERS_FAILURE:
      return { ...state, propertyManagers: { error: action.error, loading: false, data: [] } };
    case actionTypes.RESET_PROPERTIES:
      return { ...state, properties: { data: [], loading: false } };
    case actionTypes.FETCH_PROPERTY_SUCCESS:
      return { ...state, property: { ...action.data, loading: false } };
    case actionTypes.FETCH_PROPERTY_FAILURE:
      return { ...state, property: { error: action.error, loading: false } };
    case actionTypes.UPDATE_PROPERTY_SUCCESS:
      return { ...state, property: { ...action.data, loading: false } };
    case actionTypes.UPDATE_PROPERTY_FAILURE:
      return { ...state, property: { error: action.error, loading: false } };
    case actionTypes.FETCH_FLOORPLANS_SUCCESS:
      return { ...state,
        floorplans: {
          loading: false,
          data: action.data,
          ...serializePagination(action),
        } };
    case actionTypes.FETCH_FLOORPLANS_FAILURE:
      return { ...state, floorplans: { error: action.error } };
    case actionTypes.INVITE_USER_SUCCESS:
      if (action.data.user) {
        return { ...state,
          users: {
            ...state.users,
            data: [action.data.user, ...state.users.data],
          },
          propertyUsers: {
            ...state.propertyUsers,
            data: [action.data.user, ...state.propertyUsers.data],
          } };
      }
      return { ...state,
        users: {
          ...state.users,
          data: [...state.users.data],
        } };
    case actionTypes.INVITE_USER_FAILURE:
      return { ...state, invitedUser: { message: action.error.data, status: action.error.status } };
    case actionTypes.INVITE_PROPERTY_USER_SUCCESS: {
      const newState = { ...state };
      action.data.user.full_name = `${action.data.user.first_name} ${action.data.user.last_name}`;
      newState.propertyUsers.data = newState.propertyUsers.data.concat(action.data.user);
      return newState;
    } case actionTypes.FETCH_PROPERTY_USERS_SUCCESS:
      return { ...state,
        propertyUsers: {
          loading: false,
          data: action.data,
          ...serializePagination(action),
        } };
    case actionTypes.FETCH_PROPERTY_USERS_FAILURE:
      return { ...state, propertyUsers: { loading: false, error: action.error, data: [] } };
    case actionTypes.FETCH_PROPERTY_MANAGER_USERS_SUCCESS:
      return { ...state,
        users: {
          loading: false,
          data: action.data,
          ...serializePagination(action),
        } };
    case actionTypes.FETCH_PROPERTY_MANAGER_USERS_FAILURE:
      return { ...state, users: { loading: false, error: action.error } };
    case actionTypes.FETCH_PROPERTY_MANAGER_USER_SUCCESS:
      return { ...state, propertyManagerUser: { data: action.data, error: null } };
    case actionTypes.FETCH_PROPERTY_MANAGER_USER_FAILURE:
      return { ...state, propertyManagerUser: { data: action.data, error: action.error } };
    case actionTypes.CANCEL_USER_INVITE_SUCCESS: {
      const afterCancelState = { ...state };
      afterCancelState.invitedUser.message = action.message;
      afterCancelState.users.data = state.users.data.filter((user) => user.id !== action.user);
      return afterCancelState;
    } case actionTypes.REMOVE_PROPERTY_MANAGER_USER_SUCCESS: {
      const afterManagerUserRemovalState = { ...state };
      afterManagerUserRemovalState.users.data = state.users.data.filter((user) => user.id !== parseInt(action.user, 10));
      return afterManagerUserRemovalState;
    } case actionTypes.CANCEL_USER_INVITE_FAILURE:
      return { ...state,
        message: action.error.data,
        status: action.error.status };
    case actionTypes.UPDATE_PROPERTY_MANAGER_USER_SUCCESS:
      return { ...state };
    case actionTypes.FETCH_PROPERTY_NOTIFICAITONS_SUCCESS:
      return { ...state, notifications: action.data };
    case actionTypes.UPDATE_REWARDS_NOTIFICATION:
      return {
        ...state,
        notifications: {
          open_leads: state.notifications.open_leads,
          unrewarded_residents: state.notifications.unrewarded_residents + action.operand,
        },
      };
    case actionTypes.FETCH_PROSPECT_SENTIMENT_SUCCESS:
      return { ...state,
        prospectSentiment: {
          data: action.data,
          error: null,
        } };
    case actionTypes.FETCH_PROSPECT_SENTIMENT_FAILURE:
      return { ...state,
        prospectSentiment: {
          data: {
            positives: null,
            neutrals: null,
            negatives: null,
            experiences: null,
          },
          error: action.error,
        } };
    case actionTypes.FETCH_RESIDENT_SENTIMENT_SUCCESS:
      return { ...state,
        residentSentiment: {
          data: action.data,
          error: null,
        } };
    case actionTypes.FETCH_RESIDENT_SENTIMENT_FAILURE:
      return { ...state,
        residentSentiment: {
          data: {
            positives: null,
            neutrals: null,
            negatives: null,
            experiences: null,
          },
          error: action.error,
        } };
    case actionTypes.FETCH_PROXIMITY_WORDS_SUCCESS:
      return { ...state,
        proximityWords: {
          data: action.data, loading: false,
        } };
    case actionTypes.FETCH_PROXIMITY_WORDS_FAILURE:
      return { ...state, proximityWords: { error: action.error } };
    case actionTypes.FETCH_RESIDENT_RESPONSES_SUCCESS:
      return { ...state,
        residentResponses: {
          data: action.data, loading: false,
        } };
    case actionTypes.FETCH_RESIDENT_RESPONSES_FAILURE:
      return { ...state, residentResponses: { error: action.error } };
    case actionTypes.FETCH_TOP_RESIDENTS_SUCCESS:
      return { ...state,
        topResidents: {
          data: action.data, loading: false,
        } };
    case actionTypes.FETCH_TOP_RESIDENTS_FAILURE:
      return { ...state, topResidents: { error: action.error } };
    case actionTypes.FETCH_MOVED_IN_INTERESTS_SUCCESS:
      return { ...state,
        movedInInterests: {
          data: action.data, loading: false,
        } };
    case actionTypes.FETCH_MOVED_IN_INTERESTS_FAILURE:
      return { ...state, movedInInterests: { error: action.error } };
    case actionTypes.FETCH_LOST_CONCERNS_SUCCESS:
      return { ...state,
        lostConcerns: {
          data: action.data, loading: false,
        } };
    case actionTypes.FETCH_LOST_CONCERNS_FAILURE:
      return { ...state, lostConcerns: { error: action.error } };
    case actionTypes.FETCH_USER_INVITATIONS_SUCCESS:
      return { ...state,
        userInvitations: {
          error: null,
          loading: false,
          data: action.data.invite_users,
        } };
    case actionTypes.FETCH_USER_INVITATIONS_FAILURE:
      return { ...state,
        userInvitations: {
          error: action.error,
          loading: false,
          data: [],
        } };
    case actionTypes.INVITE_TEAM_SUCCESS:
      return { ...state,
        propertyUsers: {
          ...state.propertyUsers,
          data: [
            ...action.payload.invited_users.map((user) => ({ ...user, first_name: user.name.split(' ')[0], last_name: user.name.split(' ')[1], full_name: user.name })),
            ...state.propertyUsers.data,
          ],
        },
        users: {
          ...state.users,
          data: [
            ...state.users.data,
            ...action.payload.invited_users.map((user) => ({ ...user, first_name: user.name.split(' ')[0], last_name: user.name.split(' ')[1] })),
          ],
        } };
    case actionTypes.CANCEL_INACTIVE_USER_INVITE_SUCCESS:
      return { ...state,
        propertyUsers: {
          error: null,
          loading: false,
          data: state.propertyUsers.data.filter((userInvitation) => userInvitation.id !== action.payload.userId),
        } };
    case actionTypes.FETCH_PAYMENT_HISTORY_SUCCESS:
      return { ...state,
        paymentHistory: {
          error: null,
          loading: false,
          data: action.data.payment_history,
        } };
    case actionTypes.UPDATE_PROPERTY_BILLING_CONTACT_SUCCESS:
      return { ...state,
        property: {
          ...state.property,
          stripe_account_id: action.data.stripe_id,
        } };
    case actionTypes.SEARCH_MANAGER_USERS_SUCCESS:
      return { ...state,
        propertyUsers: {
          ...state.propertyUsers,
          searchedManagers: action.data,
        } };
    case actionTypes.SEARCH_MANAGER_USERS_FAILURE:
      return { ...state,
        propertyUsers: {
          ...state.propertyUsers,
          searchedManagers: [],
        } };
    case actionTypes.POST_MESSENGER_CONFIG_SUCCESS:
      return { ...state,
        propertyUsers: {
          ...state.propertyUsers,
          searchedManagers: [],
        } };
    case actionTypes.POST_MESSENGER_CONFIG_FAILURE:
      return { ...state,
        propertyUsers: {
          ...state.propertyUsers,
          searchedManagers: [],
        } };

    case actionTypes.FETCH_USER_BILLING_ACCOUNTS_SUCCESS:
      return { ...state,
        propertyBillingAccounts: {
          ...state.propertyBillingAccounts,
          status: {
            hasError: false,
          },
          creditCards: action.data.billing_accounts.credit_cards,
          bankAccounts: action.data.billing_accounts.bank_accounts,
          billingAddresses: action.data.billing_accounts.billing_addresses,
        } };

    case actionTypes.FETCH_USER_BILLING_ACCOUNTS_FAILURE:
      return { ...state,
        propertyBillingAccounts: {
          ...state.propertyBillingAccounts,
          status: {
            hasError: true,
          },
          message: action.error.message,
        } };

    case actionTypes.ADD_BILLING_ADDRESS_SUCCESS:
      return { ...state,
        propertyBillingAccounts: {
          ...state.propertyBillingAccounts,
          status: {
            hasError: false,
          },
          billingAddresses: [
            ...state.propertyBillingAccounts.billingAddresses,
            action.data.billing_account.billing_address,
          ],
        } };

    case actionTypes.ADD_STRIPE_CARD_ACCOUNT_SUCCESS:
      return { ...state,
        propertyBillingAccounts: {
          ...state.propertyBillingAccounts,
          status: {
            hasError: false,
          },
          creditCards: [
            ...state.propertyBillingAccounts.creditCards,
            action.data.billing_account.credit_card,
          ],
        } };

    case actionTypes.ADD_STRIPE_BANK_ACCOUNT_SUCCESS:
      return { ...state,
        propertyBillingAccounts: {
          ...state.propertyBillingAccounts,
          status: {
            hasError: false,
          },
          bankAccounts: [
            ...state.propertyBillingAccounts.bankAccounts,
            action.data.billing_account.bank_account,
          ],
        } };

    case actionTypes.REMOVE_STRIPE_ACCOUNT_SUCCESS:
      return { ...state,
        propertyBillingAccounts: {
          ...state.propertyBillingAccounts,
          creditCards: state.propertyBillingAccounts.creditCards
            .filter((card) => card.id !== action.data.stripe_id),
          bankAccounts: state.propertyBillingAccounts.bankAccounts
            .filter((bank) => bank.id !== action.data.stripe_id),
          status: {
            hasError: false,
          },
        } };

    case actionTypes.VERIFY_BANK_ACCOUNT_SUCCESS:
      bankAcct = state.propertyBillingAccounts.bankAccounts
        .find((acc) => acc.id === action.data.stripe_id) || {};
      bankAccounts = state.propertyBillingAccounts.bankAccounts
        .filter((acc) => acc.id !== action.data.stripe_id);
      return { ...state,
        propertyBillingAccounts: {
          ...state.propertyBillingAccounts,
          bankAccounts: [...bankAccounts, { ...bankAcct, status: 'verified' }],
        } };
    default:
      return state;
  }
};
