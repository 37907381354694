import actionTypes from '../constants/actionTypes';
import { serializePagination } from '../helpers/customMethods';

const initialResidencyState = {
  residencies: {
    loading    : true,
    data       : [],
    error      : null,
    totalPages : 0,
  },
  residentConversations: {
    data       : [],
    loading    : true,
    error      : null,
    totalPages : 0,
    currentPage: 1,
  },
  resident_rewards: {},
  resident: {},
  residentsAnalytics: {
    data : {
      new_residents_count: 0
    }
  }
};

export const residency = (state = initialResidencyState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RESIDENCIES_SUCCESS:
      return Object.assign({}, state, {
        residencies: {
          error  : null,
          loading: false,
          data   : action.data,
          ...serializePagination(action),
        }
      });
    case actionTypes.FETCH_RESIDENCIES_FAILURE:
      return Object.assign({}, state, {
        residencies: {
          loading: false,
          error  : action.error,
        }
      });
    case actionTypes.FETCH_RESIDENT_CONVERSATIONS_SUCCESS:
      return Object.assign({}, state, {
        residentConversations: {
          error  : null,
          loading: false,
          data   : action.data,
          ...serializePagination(action),
        }
      });
    case actionTypes.FETCH_RESIDENT_CONVERSATIONS_FAILURE:
      return Object.assign({}, state, {
        residentConversations: {
          loading: false,
          error  : action.error,
          data   : []
        }
      });
    case actionTypes.PAGE_LOADING:
      return Object.assign({}, state, {
        [action.key]: { ...state[action.key], loading: action.data }
      });
    case actionTypes.FETCH_RESIDENT_REWARDS_TABLE_SUCCESS:
        return Object.assign({}, state, {
          resident_rewards: action.data });
      case actionTypes.FETCH_RESIDENT_REWARDS_TABLE_FAILURE:
        return Object.assign({}, state, {
          resident_rewards: action.error
        });
    case actionTypes.FETCH_RESIDENT_SUCCESS:
      return Object.assign({}, state, { resident: action.data });
    case actionTypes.FETCH_RESIDENT_FAILURE:
      return Object.assign({}, state, { resident: action.error });
    case actionTypes.FETCH_RESIDENTS_ANALYTICS_SUCCESS:
      return Object.assign({}, state, { residentsAnalytics: {
        data: action.data,
        error: null
      }});
    case actionTypes.FETCH_RESIDENTS_ANALYTICS_FAILURE:
      return Object.assign({}, state, { residentsAnalytics: {
        data: {
          new_resident_count: 0
        },
        error: action.error
      }});
    default:
      return state;
  }
};
